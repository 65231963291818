import
{
    PASSWORD_THREE_LOADING,
    PASSWORD_THREE_SUCCESS,
    PASSWORD_THREE_FAILURE
}
from "redux/types";

const initialState = {
    loading: false,
    data: null,
    errros: null
}

export const forgotPasswordThreeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PASSWORD_THREE_LOADING :
            return {...state, loading: action.payload};
        case PASSWORD_THREE_SUCCESS :
            return {...state, data: action.payload};
        case PASSWORD_THREE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}