import
{
    STEP_THREE_LOADING,
    STEP_THREE_SUCCESS,
    STEP_THREE_FAILURE
}
from "redux/types";

const initialState = {
    stepThreeLoading: false,
    stepThreeData: null,
    errors: null
}

export const stepThreeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STEP_THREE_LOADING :
            return {...state, stepThreeLoading: action.payload};
        case STEP_THREE_SUCCESS :
            return {...state, stepThreeData: action.payload};
        case STEP_THREE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}