import
{
    GEN_OTP_LOADING,
    GEN_OTP_SUCCESS,
    GEN_OTP_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { generateOtpEndpoint } from "api/apiUrls";

export const genOtpAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GEN_OTP_LOADING, payload: true});
        const response = await createNewItemService(generateOtpEndpoint, data, "json");
        dispatch({type: GEN_OTP_SUCCESS, payload: response.data});
        callback && callback();
    }   
    catch (err) {
        dispatch({type: GEN_OTP_FAILURE, payload: err});
    }
    finally {
        dispatch({type: GEN_OTP_LOADING, payload: false});
    }
}