import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";

const Subscription = () => {
  const [cardsOpen, setCardsOpen] = useState(false);
  const [offersHovered, setOffersHovered] = useState(false);
  const [overviewHovered, setOverviewHovered] = useState(false);

  const [is625, setIs625] = useState(false);

  const cardAnimationControls = useAnimationControls();

  const handleOpenCard = () => {
    if (cardsOpen) {
      setCardsOpen(false);
      cardAnimationControls.start("hidden");
    } else {
      setCardsOpen(true);
      cardAnimationControls.start("visible");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 625) {
        setIs625(true);
      } else {
        setIs625(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 625) {
      setIs625(true);
    } else {
      setIs625(false);
    }
  }, []);

  return (
    <section className="w-full bg-white pt-6 pb-6 scrn-625:pt-20 gap-x-4">
      <div className="w-full mx-auto max-w-[1440px] flex flex-col-reverse scrn-625:flex-row gap-x-5 items-start justify-center">
        <div className="relative w-full scrn-625:w-auto flex flex-col items-start scrn-625:items-center gap-y-3 bg-main scrn-625:bg-white px-3 py-3 scrn-625:px-0 scrn-625:py-0">
          {is625 ? (
            <div className="w-full flex flex-row items-center justify-between">
              <motion.div
                variants={{
                  hidden: {
                    scale: 1,
                  },
                  visible: {
                    scale: 1.1,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                onClick={handleOpenCard}
                className="bg-[#5E85ED] w-[88px] h-[88px] rounded-full z-20 text-white flex items-center justify-center text-[20px] text-center hover:cursor-pointer"
              >
                <p className="max-w-[95%]">About Intrn</p>
              </motion.div>
              <motion.div
                variants={{
                  hidden: {
                    x: 0,
                  },
                  visible: {
                    x: 115,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                style={{
                  borderRadius: "9999px",
                  zIndex: 10,
                  backgroundColor: "#DCDEE3",
                }}
                className="w-[70px] h-[70px] absolute text-main flex items-center justify-center text-[16px] text-center hover:cursor-pointer"
              >
                {offersHovered ? (
                  <div className="w-full h-full flex flex-col items-start gap-y-4 text-white text-[14px] px-3 py-4">
                    <p>What We Offer</p>
                    <p className="text-left text-[12px]">
                      Intrn is Egypt's premier platform dedicated to bridging
                      the gap between ambitious young talent and innovative
                      companies. We streamline the hiring process for
                      internships and entry-level positions, making it easier
                      than ever for you to find the perfect match for your
                      business needs.
                    </p>
                  </div>
                ) : (
                  <p className="max-w-[95%]">What We Offer</p>
                )}
              </motion.div>
              <motion.div
                variants={{
                  hidden: {
                    x: 0,
                  },
                  visible: {
                    x: 210,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                className="bg-[#DCDEE3] w-[70px] h-[70px] z-10 absolute rounded-full text-main flex items-center justify-center text-[16px] text-center hover:cursor-pointer"
              >
                {overviewHovered ? (
                  <div className="w-full h-full flex flex-col items-start gap-y-4 text-white text-[14px] px-3 py-4">
                    <p>Overview</p>
                    <p className="text-left text-[12px]">
                      Intrn is Egypt's premier platform dedicated to bridging
                      the gap between ambitious young talent and innovative
                      companies. We streamline the hiring process for
                      internships and entry-level positions, making it easier
                      than ever for you to find the perfect match for your
                      business needs.
                    </p>
                  </div>
                ) : (
                  <p className="max-w-[95%]">Overview</p>
                )}
              </motion.div>
            </div>
          ) : (
            <>
              <motion.div
                variants={{
                  hidden: {
                    scale: 1,
                  },
                  visible: {
                    scale: 1.1,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                onClick={handleOpenCard}
                className="bg-[#5E85ED] w-[104px] h-[104px] rounded-full z-20 text-white flex items-center justify-center text-[20px] text-center hover:cursor-pointer"
              >
                <p className="max-w-[95%]">About Intrn</p>
              </motion.div>
              <motion.div
                variants={{
                  hidden: {
                    y: 0,
                  },
                  visible: {
                    y: 115,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                style={{
                  borderRadius: "9999px",
                  zIndex: 10,
                  backgroundColor: "#DCDEE3",
                }}
                whileHover={{
                  width: 318,
                  height: 178,
                  borderRadius: "15px",
                  left: "10%",
                  zIndex: 15,
                  backgroundColor: "#5E85ED",
                }}
                onHoverStart={() => setOffersHovered(true)}
                onHoverEnd={() => setOffersHovered(false)}
                className="w-[88px] h-[88px] absolute text-main flex items-center justify-center text-[18px] text-center hover:cursor-pointer"
              >
                {offersHovered ? (
                  <div className="w-full h-full flex flex-col items-start gap-y-4 text-white text-[14px] px-3 py-4">
                    <p>What We Offer</p>
                    <p className="text-left text-[12px]">
                      Intrn is Egypt's premier platform dedicated to bridging
                      the gap between ambitious young talent and innovative
                      companies. We streamline the hiring process for
                      internships and entry-level positions, making it easier
                      than ever for you to find the perfect match for your
                      business needs.
                    </p>
                  </div>
                ) : (
                  <p className="max-w-[95%]">What We Offer</p>
                )}
              </motion.div>
              <motion.div
                variants={{
                  hidden: {
                    y: 0,
                  },
                  visible: {
                    y: 210,
                  },
                }}
                initial="hidden"
                animate={cardAnimationControls}
                whileHover={{
                  width: 318,
                  height: 178,
                  borderRadius: "15px",
                  left: "10%",
                  zIndex: 15,
                  backgroundColor: "#5E85ED",
                }}
                onHoverStart={() => setOverviewHovered(true)}
                onHoverEnd={() => setOverviewHovered(false)}
                className="bg-[#DCDEE3] w-[88px] h-[88px] z-10 absolute rounded-full text-main flex items-center justify-center text-[18px] text-center hover:cursor-pointer"
              >
                {overviewHovered ? (
                  <div className="w-full h-full flex flex-col items-start gap-y-4 text-white text-[14px] px-3 py-4">
                    <p>Overview</p>
                    <p className="text-left text-[12px]">
                      Intrn is Egypt's premier platform dedicated to bridging
                      the gap between ambitious young talent and innovative
                      companies. We streamline the hiring process for
                      internships and entry-level positions, making it easier
                      than ever for you to find the perfect match for your
                      business needs.
                    </p>
                  </div>
                ) : (
                  <p className="max-w-[95%]">Overview</p>
                )}
              </motion.div>
            </>
          )}
        </div>
        <div className="w-full scrn-625:w-[80%] py-5 scrn-625:h-[423px] bg-main scrn-625:rounded-[56px] flex flex-col items-center justify-center gap-y-8">
          <h2 className="text-secondary text-2xl scrn-488:text-4xl scrn-975:text-[56px] font-bold">
            <span className="text-[rgb(94,133,237)]">Effortless</span>{" "}
            Subscription Plans
          </h2>
          <p className="text-white text-center max-w-[95%] scrn-488:max-w-[80%] scrn-750:max-w-[65%] text-[16px] scrn-975:text-[20px]">
            Our flexible subscription plans are designed to cater to companies
            of all sizes, providing tailored solutions to help you connect with
            the best candidates effortlessly. Explore the possibilities without
            the hassle.
          </p>
          <a
            href="/subscription-plans"
            className="bg-[#5E85ED] rounded-full min-w-[240px] min-h-[48px] flex items-center justify-center text-white text-[20px]"
          >
            Subscription plans
          </a>
        </div>
      </div>
    </section>
  );
};

export default Subscription;
