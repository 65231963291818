import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Logo } from "assets/icons";
import { SIDE_BAR_ITEMS, SidebarItemsProps } from "constants/index";

const CompaniesSideBar = () => {
  const [selectedItem, setSelectedItem] = useState("Home");
  const [highlightedItem, setHighlightedItem] = useState("");

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setSelectedItem("Home");
      return;
    }
    if (window.location.pathname.includes("vacancies")) {
      setSelectedItem("Vacancies");
      return;
    }
    if (window.location.pathname.includes("profile")) {
      setSelectedItem("Profile");
      return;
    }
    if (window.location.pathname.includes("applications")) {
      setSelectedItem("Applications");
      return;
    }
  }, [pathname]);

  return (
    <div className="fixed left-0 top-0 w-[300px] h-full overflow-x-hidden flex flex-col items-center py-12 gap-y-10 bg-dashboard_primary">
      <img src={Logo} alt="logo" />
      <div className="w-full flex flex-col items-center gap-y-6">
        {SIDE_BAR_ITEMS.map((item: SidebarItemsProps) => {
          return (
            <button
              onClick={async () => {
                setHighlightedItem("");
                if (item.name === "Home") {
                  //   await dispatch(
                  //     getCompanyProfileAction(
                  //       companyId === null ? companyData?.companyId : companyId
                  //     )
                  //   );
                } else if (item.name === "Profile") {
                  //   await dispatch(
                  //     getCompanyProfileAction(
                  //       companyId === null ? companyData?.companyId : companyId
                  //     )
                  //   );
                }
                navigate(item.route);
              }}
              onMouseOver={() => {
                if (selectedItem !== item.name) {
                  setHighlightedItem(item.name);
                }
              }}
              onMouseLeave={() => {
                setHighlightedItem("");
              }}
              className={`w-[80%] py-3 px-3 rounded-lg flex flex-row items-center justify-start gap-x-3 ${
                selectedItem === item.name
                  ? "bg-dashboard_secondary"
                  : "bg-transparent"
              }`}
            >
              <img
                className="w-[1.3rem] h-[1.3rem]"
                src={
                  highlightedItem === item.name
                    ? item.highlightedIcon
                    : selectedItem === item.name
                    ? item.selectedIcon
                    : item.icon
                }
                alt="dashboard-icon"
              />
              <p
                className={`${
                  highlightedItem === item.name
                    ? "text-dashboard_secondary"
                    : selectedItem === item.name
                    ? "text-black"
                    : "text-white"
                }`}
              >
                {item.name}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CompaniesSideBar;
