import
{
    STEP_TWO_LOADING,
    STEP_TWO_SUCCESS,
    STEP_TWO_FAILURE
}
from "redux/types";

const initialState = {
    stepTwoLoading: false,
    stepTwoData: null,
    errors: null
}

export const stepTwoReducer = (state = initialState, action: any) => {
    switch (action.type)  {
        case STEP_TWO_LOADING :
            return {...state, stepTwoLoading: action.payload};
        case STEP_TWO_SUCCESS :
            return {...state, stepTwoData: action.payload};
        case STEP_TWO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}