import
{
    PASSWORD_ONE_LOADING,
    PASSWORD_ONE_SUCCESS,
    PASSWORD_ONE_FAILURE
}
from "redux/types";

const initialState = {
    loading: false,
    data: null,
    errros: null
}

export const forgotPasswordOneReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PASSWORD_ONE_LOADING :
            return {...state, loading: action.payload};
        case PASSWORD_ONE_SUCCESS :
            return {...state, data: action.payload};
        case PASSWORD_ONE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}