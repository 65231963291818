import { EmailIcon, LogoDark, PhoneIcon } from "assets/icons";

const Footer = () => {
  return (
    <footer className="w-full bg-[#9AADBF] px-10 scrn-650:px-36 pt-16">
      <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-y-4">
        <div className="w-full flex flex-row justify-start gap-x-24">
          <div className="flex flex-col items-start gap-y-5">
            <img src={LogoDark} alt="logo" />
            <p className="text-[#444444] text-[16px] max-w-[435px]">
              Intrn is Egypt's premier platform dedicated to bridging the gap
              between ambitious young talent and innovative companies. We
              streamline the hiring process for internships and entry-level
              positions, making it easier than ever for you
            </p>
            <div className="hidden scrn-795:flex scrn-1036:hidden flex-col items-start text-[14px] pt-12 gap-y-3">
              <p>Download Intrn Application Now</p>
              <a href="tel:+201145789820" className="flex items-center gap-x-3">
                <img src={PhoneIcon} alt="phone" />
                <p>+20 1145 7898 20</p>
              </a>
              <a
                href="mailto:Mohamed@gmail.com"
                className="flex items-center gap-x-3"
              >
                <img src={EmailIcon} alt="email" />
                <p>Mohamed@gmail.com</p>
              </a>
            </div>
            <div className="flex scrn-795:hidden flex-row gap-x-12">
              <div className="flex flex-col items-start text-[14px] pt-12 gap-y-4">
                <p>Download Intrn Application Now</p>
                <a href="tel:+201145789820" className="flex items-center gap-x-3">
                  <img src={PhoneIcon} alt="phone" />
                  <p>+20 1145 7898 20</p>
                </a>
                <a
                  href="mailto:Mohamed@gmail.com"
                  className="flex items-center gap-x-3"
                >
                  <img src={EmailIcon} alt="email" />
                  <p>Mohamed@gmail.com</p>
                </a>
                <div className="flex scrn-391:hidden flex-col items-start gap-y-3 pt-12">
                  <a href="/" className="text-black">
                    Important Links
                  </a>
                  <a href="/" className="text-black">
                    Employer Registeration
                  </a>
                  <a href="/" className="text-black">
                    Blog
                  </a>
                </div>
              </div>
              <div className="hidden scrn-391:flex flex-col items-start gap-y-3 pt-12">
                <a href="/" className="text-black">
                  Important Links
                </a>
                <a href="/" className="text-black">
                  Employer Registeration
                </a>
                <a href="/" className="text-black">
                  Blog
                </a>
              </div>
            </div>
          </div>
          <div className="hidden scrn-795:flex flex-col items-start gap-y-3 pt-12">
            <a href="/" className="text-black">
              Important Links
            </a>
            <a href="/" className="text-black">
              Employer Registeration
            </a>
            <a href="/" className="text-black">
              Blog
            </a>
          </div>
          <div className="hidden scrn-1036:flex flex-col items-start text-[14px] pt-12 gap-y-3">
            <a href="tel:+201145789820" className="flex items-center gap-x-3">
              <img src={PhoneIcon} alt="phone" />
              <p>+20 1145 7898 20</p>
            </a>
            <a
              href="mailto:Mohamed@gmail.com"
              className="flex items-center gap-x-3"
            >
              <img src={EmailIcon} alt="email" />
              <p>Mohamed@gmail.com</p>
            </a>
          </div>
        </div>
        <div className="w-full h-[0.5px] bg-main mt-4" />
        <p className="text-center">© intrn 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
