import api from "api";
import store from "redux/store";

export const createNewProtectedItemService = async (url: string, data: any, contentType?: "json" | "formData") => {
    const response = await api.post(
        url,
        data,
        {
            headers: {
                "Content-Type" : contentType === "json" ? "application/json" : "multipart/form-data",
                "Authorization": `Bearer ${store.getState()?.stepOneReducer?.token}`
            }
        }
    );

    return response;
}