import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import { listMajorsAction } from "redux/actions/utilActions/listMajorsAction";
import { listCountriesAction } from "redux/actions/utilActions/listCountriesAction";

const useDropdown = () => {
    const dispatch: any = useDispatch();

    const { majors } =  useSelector((state: RootState) => state.listMajorsReducer);
    const { countries } =  useSelector((state: RootState) => state.listCountriesReducer);

    

    const majorsDropdown = majors?.map((item: any) => {
        return {
            label: item?.companyFieldEn,
            value: item?.companyFieldId
        }
    });

    const countriesDropdown = countries?.map((item: any) => {
        return {
            label: item?.countryNameEn,
            value: item?.countryId
        }
    });

    useEffect(() => {
        dispatch(listMajorsAction());
        dispatch(listCountriesAction());
    }, []);

    return {
        majorsDropdown,
        countriesDropdown
    }
}

export {
    useDropdown
}