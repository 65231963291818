import
{
    LIST_VACANCY_LOADING,
    LIST_VACANCY_SUCCESS,
    LIST_VACANCY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listSingleVacancyEndpoint } from "api/apiUrls";

export const listSingleVacancyAction = (id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_VACANCY_LOADING, payload: true});
        const response = await listProtectedItemsService(`${listSingleVacancyEndpoint}/${id}`);
        dispatch({type: LIST_VACANCY_SUCCESS, payload: response.data});
       callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: LIST_VACANCY_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_VACANCY_LOADING, payload: false});
    }
}