import { Spin } from "antd";
import { Logo, LogoWhite, RoundedClose } from "assets/icons";
import InputField from "../ui/InputField";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useLoginFormik } from "forms/loginFormik";
import { RootState } from "redux/rootReducer";
import { useSelector } from "react-redux";

const SignInModal = ({ setActiveModal }: { setActiveModal: any }) => {
  const formik = useLoginFormik();

  const { loginLoading } = useSelector(
    (state: RootState) => state.loginReducer
  );

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[15px]">
          <button
            onClick={() => setActiveModal(false)}
            className="w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-white font-bold text-2xl">Welcome Back!</p>
          <p className="text-white">Sign in to continue</p>
          <img src={LogoWhite} alt="logo" className="mt-4" />
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col items-start gap-y-6 px-4 pt-10"
          >
            <InputField
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              errors={formik.errors.email}
            />
            <div className="w-full flex flex-col items-start gap-y-2">
              <InputField
                placeholder="Password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                errors={formik.errors.password}
              />

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => setActiveModal("FORGOT_PASSWORD_ONE")}
                className="text-main font-normal hover:text-secondary"
              >
                Forgot Password?
              </a>

              <p className="text-white">
                Don&apos;t have an account? sign up{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={() => setActiveModal("SIGNUP")}
                  className="text-main hover:text-secondary"
                >
                  here
                </a>
              </p>
            </div>
            <FormSubmitButton body="Sign In" loading={loginLoading} />
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default SignInModal;
