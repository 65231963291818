import
{
    DELETE_VACANCY_LOADING,
    DELETE_VACANCY_SUCCESS,
    DELETE_VACANCY_FAILURE
}   
from "redux/types";

import { Dispatch } from "redux";

import { createNewProtectedItemService } from "services/createNewProtectedItemService";
import { notification } from "antd";
import { deleteVacancyEndpoint } from "api/apiUrls";

export const deleteVacancyAction = (id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_VACANCY_LOADING, payload: true});
        const response = await createNewProtectedItemService(`${deleteVacancyEndpoint}/${id}`, {}, "json");
        dispatch({type: DELETE_VACANCY_SUCCESS, payload: response.data});
        if (response.status === 200) {
            notification.success({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Success" : response.data.message
            });
            callback && callback();
        }
        else {
            notification.error({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Something went wrong" : response.data.message
            });
        }
    }   
    catch (err) {
        dispatch({type: DELETE_VACANCY_FAILURE, payload: err});
    }
    finally {
        dispatch({type: DELETE_VACANCY_LOADING, payload: false});
    }
}