import { useFormik } from "formik"
import * as yup from "yup";
import UpdateProfileModel from "models/UpdateProfileModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "redux/actions/updateProfileAction";
import { notification } from "antd";
import { getUserDataAction } from "redux/actions/getUserDataAction";

const useUpdateProfileFormik = () => {
    const { userData } = useSelector((state: RootState) => state.getUserDataReducer);

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            bio: userData?.bio,
            companyName: userData?.companyName,
            countOfEmployees: userData?.countOfEmployees,
            cityId: userData?.cityId,
            countryId: userData?.countryId,
            coverLogo: userData?.coverLogo,
            facebookLink: '',
            foundationYear: userData?.foundationYear,
            instagramLink: '',
            linkedInLink: '',
            companyFieldId: userData?.companyFieldId,
            profileLogo: userData?.profileLogo,
            webSite: '',
            xLink: ''
        },
        validationSchema: yup.object({
            bio: yup.string().required(DEFAULT_ERROR_MSG),
            companyName: yup.string().required(DEFAULT_ERROR_MSG),
            countOfEmployees: yup.string().required(DEFAULT_ERROR_MSG),
            companyFieldId: yup.string().required(DEFAULT_ERROR_MSG),
            countryId: yup.string().required(DEFAULT_ERROR_MSG),
            cityId: yup.string().required(DEFAULT_ERROR_MSG),
            foundationYear: yup.string().required(DEFAULT_ERROR_MSG),
        }),
        onSubmit: async (values: UpdateProfileModel) => {
            const formObject: any = {
                bio: values.bio,
                companyName: values.companyName,
                countOfEmployees: values.countOfEmployees,
                countryId: values.countryId,
                cityId: values.cityId,
                companyFieldId: values.companyFieldId,
                foundationYear: values.foundationYear,
                facebookLink: values.facebookLink,
                instagramLink: values.instagramLink,
                linkedInLink: values.linkedInLink,
                webSite: values.webSite,
                xLink: values.xLink,
            };

            if (typeof values.profileLogo !== "string") {
                formObject.profileLogo = values.profileLogo;
            }
        
            if (typeof values.coverLogo !== "string") {
                formObject.coverLogo = values.coverLogo;
            }

            await dispatch(
                updateProfileAction(
                    formObject,
                    () => {
                        notification.success({
                            message: "Company Data Updated Successfully",
                            description: "Your company data has been updated successfully",
                        });
                        dispatch(getUserDataAction());
                    }
                )
            );
        }
    });

    return formik;
}

export {
    useUpdateProfileFormik
}