import { useFormik } from "formik";
import * as yup from "yup";
import StepTwoRegisterModel from "models/StepTwoRegisterModel";
import { DEFAULT_ERROR_MSG } from "constants/index";

const useStepTwoFormik = (setState: React.Dispatch<React.SetStateAction<string>>) => {
    const formik = useFormik({
        initialValues: {
            Bio: '',
            CompanyFieldId: '',
            CountryId: '',
            CityId: '',
        },
        validationSchema: yup.object({
            Bio: yup.string().required(DEFAULT_ERROR_MSG),
            CompanyFieldId: yup.string().required(DEFAULT_ERROR_MSG),
            CountryId: yup.string().required(DEFAULT_ERROR_MSG),
            CityId: yup.string().required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: (values: StepTwoRegisterModel) => {
            setState("STEPTHREE");
        }
    });

    return formik;
}

export {
    useStepTwoFormik
}