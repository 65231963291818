import React, { useEffect } from "react";
import CompaniesSideBar from "components/CompaniesSideBar/CompaniesSideBar";
import CompaniesNavbar from "components/CompaniesNavbar/CompaniesNavbar";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { getUserCompletionAction } from "redux/actions/getUserCompletionAction";
import { useNavigate } from "react-router";
import { HOME_PATH } from "Routes/paths";

type DashboardParent = {
  children: React.ReactNode;
};

const Dashboard = ({ children }: DashboardParent) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { userDataLoading } = useSelector(
    (state: RootState) => state.getUserDataReducer
  );
  const { userCompletionLoading } = useSelector(
    (state: RootState) => state.getUserCompletionReducer
  );
  const { vacanciesLoading } = useSelector(
    (state: RootState) => state.listVacanciesReducer
  );
  const { vacAppLoading } = useSelector(
    (state: RootState) => state.listVacancyApplicationsReducer
  );
  const { applicationLoading } = useSelector(
    (state: RootState) => state.listSingleApplicationReducer
  );
  const { applicantLoading } = useSelector(
    (state: RootState) => state.listApplicantReducer
  );

  return (
    <Spin
      wrapperClassName="full-width-spin"
      spinning={
        userDataLoading ||
        userCompletionLoading ||
        vacanciesLoading ||
        vacAppLoading ||
        applicationLoading ||
        applicantLoading
      }
    >
      <div className="w-full h-full bg-[#e6edf5] flex flex-row overflow-x-hidden">
        <CompaniesSideBar />
        <div
          className={`ml-[300px] w-full h-full min-h-screen flex flex-col overflow-x-hidden`}
        >
          <CompaniesNavbar />
          <div className="w-full h-full pt-[80px] overflow-y-hidden">
            {children}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Dashboard;
