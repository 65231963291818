import
{
    LIST_COUNTRIES_LOADING,
    LIST_COUNTRIES_SUCCESS,
    LIST_COUNTRIES_FAILURE
}
from "redux/types";

const initialState = {
    countriesLoading: false,
    countries: null,
    errors: null
}

export const listCountriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_COUNTRIES_LOADING :
            return {...state, countriesLoading: action.payload};
        case LIST_COUNTRIES_SUCCESS :
            return {...state, countries: action.payload};
        case LIST_COUNTRIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}