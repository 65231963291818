import { useFormik } from "formik";
import * as yup from "yup";
import StepThreeRegisterModel from "models/StepThreeRegisterModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { stepTwoAction } from "redux/actions/registerActions/stepTwoAction";

const useStepThreeFormik = (otherData: any, setState: React.Dispatch<React.SetStateAction<string>>) => {
    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            CountOfEmployees: '',
            FoundationYear: '',
            TaxCard: null,
            CommercialRecord: null,
        },
        validationSchema: yup.object({
            CountOfEmployees: yup.string().required(DEFAULT_ERROR_MSG),
            FoundationYear: yup.string().required(DEFAULT_ERROR_MSG),
        }), 
        onSubmit: (values: StepThreeRegisterModel) => {
            const formData: any = {
                CountOfEmployees: values.CountOfEmployees,
                FoundationYear: values.FoundationYear,
                Bio: otherData.values.Bio,
                CompanyFieldId: otherData.values.CompanyFieldId,
                CountryId: otherData.values.CountryId,
                CityId: otherData.values.CityId
            };

            if (values.TaxCard === null) {
                formik.setFieldError("TaxCard", DEFAULT_ERROR_MSG);
                return;
            }
            if (values.CommercialRecord === null) {
                formik.setFieldError("CommercialRecord", DEFAULT_ERROR_MSG);
                return;
            }

            formData.TaxCard = values.TaxCard;
            formData.CommercialRecord = values.CommercialRecord;

            dispatch(stepTwoAction(formData, () => {
                setState("SOCIALLINKS");
            }));
        }
    });

    return formik;
}

export {
    useStepThreeFormik
}