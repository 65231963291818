import
{
    GEN_OTP_LOADING,
    GEN_OTP_SUCCESS,
    GEN_OTP_FAILURE
}
from "redux/types";

const initalState = {
    genOtpLoading: false,
    success: null,
    errors: null
}

export const genOtpReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case GEN_OTP_LOADING :
            return {...state, genOtpLoading: action.payload};
        case GEN_OTP_SUCCESS :
            return {...state, success: action.payload.success};
        case GEN_OTP_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}