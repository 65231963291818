export const LOGIN_PATH = "/login";
export const HOME_PATH = "/";
export const SUBSCRIPTION_PLANS_PATH = "/subscription-plans";

export const DASHBOARD_HOME_PATH = "/dashboard";
export const DASHBOARD_PROFILE_PATH = `${DASHBOARD_HOME_PATH}/profile`
export const DASHBOARD_VACANCIES_PATH = `${DASHBOARD_HOME_PATH}/vacancies`;
export const DASHBOARD_CREATE_VACANCY_PATH = `${DASHBOARD_HOME_PATH}/vacancies/create`;
export const DASHBOARD_UPDATE_VACANCY_PATH = `${DASHBOARD_HOME_PATH}/vacancies/update`;
export const DASHBOARD_APPLICATIONS_PATH = `${DASHBOARD_HOME_PATH}/applications`;
export const DASHBOARD_VIEW_APPLICATION_PATH = `${DASHBOARD_HOME_PATH}/applications/:id/view`;
export const DASHBOARD_DOWNLOAD_CV_PATH = `${DASHBOARD_HOME_PATH}/cv/download`;