import
{
    PASSWORD_ONE_LOADING,
    PASSWORD_ONE_SUCCESS,
    PASSWORD_ONE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { forgotPasswordOneEndpoint } from "api/apiUrls";
import { notification } from "antd";

export const forgotPasswordOneAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: PASSWORD_ONE_LOADING, payload: true});
        const response = await createNewItemService(forgotPasswordOneEndpoint, data, "json");
        dispatch({type: PASSWORD_ONE_SUCCESS, payload: response.data});
        if (response.status === 200) {
            callback && callback();
            notification.success({
                message: 'OTP sent to your email'
            });
        }
    }   
    catch (err) {
        dispatch({type: PASSWORD_ONE_FAILURE, payload: err});
    }
    finally {   
        dispatch({type: PASSWORD_ONE_LOADING, payload: false});
    }
}