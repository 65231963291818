import
{
    USER_COMPLETION_LOADING,
    USER_COMPLETION_SUCCESS,
    USER_COMPLETION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewProtectedItemService } from "services/createNewProtectedItemService";
import { checkCompletionEndpoint } from "api/apiUrls";

export const getUserCompletionAction = (callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: USER_COMPLETION_LOADING, payload: true});
        const response = await createNewProtectedItemService(checkCompletionEndpoint, {}, "json");
        dispatch({type: USER_COMPLETION_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: USER_COMPLETION_FAILURE, payload: err});
    }
    finally {
        dispatch({type: USER_COMPLETION_LOADING, payload: false});
    }
}