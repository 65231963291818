import
{
    STEP_ONE_LOADING,
    STEP_ONE_SUCCESS,
    STEP_ONE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { stepOneEndpoint } from "api/apiUrls";
import { notification } from "antd";

export const stepOneAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: STEP_ONE_LOADING, payload: true});
        const response = await createNewItemService(stepOneEndpoint, data, "formData");
        dispatch({type: STEP_ONE_SUCCESS, payload: response.data});
        if (response.status === 200) {
            notification.success({
                message: response.data.message
            });
        }
        else {
            notification.error({
                message: response.data.message
            });
        }
        callback && callback();
    }
    catch (err: any) {
        dispatch({type: STEP_ONE_FAILURE, payload: err});
    }
    finally {
        dispatch({type: STEP_ONE_LOADING, payload: false});
    }
}