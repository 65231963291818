import
{
    CREATE_VACANCY_LOADING,
    CREATE_VACANCY_SUCCESS,
    CREATE_VACANCY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewProtectedItemService } from "services/createNewProtectedItemService";
import { notification } from "antd";
import { createVacancyEndpoint } from "api/apiUrls";

export const createVacancyAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_VACANCY_LOADING, payload: true});
        const response = await createNewProtectedItemService(createVacancyEndpoint, data, "json");
        dispatch({type: CREATE_VACANCY_SUCCESS, payload: response.data});
        if (response.status === 200 || response.status === 201) {
            notification.success({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Success" : response.data.message
            });
        }
        else {
            notification.error({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Something went wrong" : response.data.message
            });
        }
        callback && callback();
    }   
    catch (err) {
        dispatch({type: CREATE_VACANCY_FAILURE, payload: err});
    }
    finally {
        dispatch({type: CREATE_VACANCY_LOADING, payload: false});
    }
}