import
{
    LIST_COUNTRIES_LOADING,
    LIST_COUNTRIES_SUCCESS,
    LIST_COUNTRIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";
import { listCountriesEndpoint } from "api/apiUrls";

export const listCountriesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_COUNTRIES_LOADING, payload: true});
        const response = await listItemsService(listCountriesEndpoint);
        dispatch({type: LIST_COUNTRIES_SUCCESS, payload: response.data});
    }   
    catch (err) {
        dispatch({type: LIST_COUNTRIES_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_COUNTRIES_LOADING, payload: false});
    }
}