import api from "api";

export const createNewItemService = async (url: string, data: any, contentType?: "json" | "formData") => {
    const response = await api.post(
        url,
        data,
        {
            headers: {
                "Content-Type": contentType === "json" ? "application/json" : "multipart/form-data"
            }
        }
    );

    return response;
}