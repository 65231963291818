import { FC } from "react";

export interface AdditionalPostsButtonProps {
  id: number;
  amountAdd: number;
  featureText: string;
  additionalFeatureText?: string;
  setState?: any;
  value?: number;
  selected?: number;
  setSelected?: any;
  siblingAmount?: number;
}

const AdditionalPosts: FC<AdditionalPostsButtonProps> = ({
  id,
  amountAdd,
  featureText,
  additionalFeatureText,
  setState,
  value,
  selected,
  setSelected,
  siblingAmount,
}) => {
  const handleAddAmount = () => {
    if (selected !== -1) {
      setState((prev: number) => prev - (siblingAmount ?? 0) + amountAdd);
    } else {
      setState((prev: number) => prev + amountAdd);
    }
  };

  const handleRemoveAmount = () => {
    setState((prev: number) => prev - amountAdd);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-y-2">
      <button
        type="button"
        onClick={() => {
          if (selected !== value) {
            handleAddAmount();
          } else {
            handleRemoveAmount();
          }

          if (selected === value) {
            setSelected(-1);
          } else {
            setSelected(value);
          }
        }}
        className={`flex items-center justify-center py-1 px-8 border-2 min-w-[217px] max-w-[217px] rounded-[15px] border-pro_text_light ${
          selected === value ? "bg-pro_text_light" : "text-transparent"
        }`}
      >
        <p className="text-[#142644] text-[12px] md:text-[16px] text-center font-Avenir_demi">
          {amountAdd === 0 ? "For Free" : amountAdd}{" "}
          {amountAdd === 0 ? "" : "EGP"} <br /> / {featureText}
        </p>
      </button>
      {additionalFeatureText && (
        <p className="text-[#444444] font-Avenir_regular text-[10px] md:text-[13px] max-w-[192px] text-center">
          {additionalFeatureText}
        </p>
      )}
    </div>
  );
};

export default AdditionalPosts;
