import { useFormik } from "formik"
import * as yup from "yup";
import LoginModel from "models/LoginModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { loginAction } from "redux/actions/loginAction";

const useLoginFormik = () => {
    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: yup.object({
            email: yup.string().email('Please enter a valid email').required(DEFAULT_ERROR_MSG),
            password: yup.string().required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: async (values: LoginModel) => {
            await dispatch(loginAction({
                email: values.email,
                password: values.password
            }));
        }
    });

    return formik;
}

export {
    useLoginFormik
}