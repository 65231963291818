import { notification, Select, Spin } from "antd";
import { DownArrowBlack } from "assets/icons";
import { APPLICATION_STATUS_DROPDOWN, BASE_PDF_URL } from "constants/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router";
import { updateApplicationStatusAction } from "redux/actions/applicationActions/updateApplicationStatusAction";
import { useEffect } from "react";
import { listSingleApplicationAction } from "redux/actions/applicationActions/listSingleApplicationAction";

const ViewApplication = () => {
  const { id } = useParams();

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { application, applicationLoading } = useSelector(
    (state: RootState) => state.listSingleApplicationReducer
  );
  const { applicant, applicantLoading } = useSelector(
    (state: RootState) => state.listApplicantReducer
  );
  const { updateLoading } = useSelector(
    (state: RootState) => state.updateApplicationStatusReducer
  );

  useEffect(() => {
    if (application?.applicationStatusId === 1) {
      dispatch(
        updateApplicationStatusAction({
          applicationId: id,
          newStatus: 4,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(listSingleApplicationAction(id?.toString() ?? "1"));
  }, []);

  return (
    <div className="pt-10 px-8 w-full h-full flex flex-col items-start gap-y-8">
      <h2 className="text-black text-2xl font-bold">Application Information</h2>
      <div className="w-full flex flex-col items-start gap-y-4">
        <div className="w-full sm:w-[90%] md:w-[70%] lg:w-[50%] flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Application Status</p>
          <Spin
            wrapperClassName="spin-full-width"
            spinning={applicationLoading || updateLoading}
          >
            <Select
              className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Appliaction Status"
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              options={APPLICATION_STATUS_DROPDOWN}
              value={application?.applicationStatus?.applicationStatusId}
              onSelect={async (value: any) => {
                await dispatch(
                  updateApplicationStatusAction(
                    {
                      applicationId: id,
                      newStatus: value,
                    },
                    () => {
                      notification.success({
                        message: "Application Status Updated",
                        description: `Applicant ${applicant?.firstName} ${
                          applicant?.lastName
                        } has been ${
                          value === 2
                            ? "Accepted"
                            : value === 3
                            ? "Rejected"
                            : "Shortlisted"
                        }`,
                      });
                      dispatch(
                        listSingleApplicationAction(id?.toString() ?? "1")
                      );
                    }
                  )
                );
              }}
            />
          </Spin>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Application Status</p>
          <p>{application?.applicationStatus?.nameEn}</p>
        </div>
      </div>

      <h2 className="text-black text-2xl font-bold">Student Information</h2>
      <div className="w-full flex flex-col items-start gap-y-8 pb-[5%]">
        <Button
          type="button"
          onClick={() => {
            if (applicant?.cvFileName) {
              window.location.href = BASE_PDF_URL + applicant?.cvFileName;
            } else {
              navigate(`/dashboard/cv/download`);
            }
          }}
          bgColor="#142644"
          fontSize={16}
          px={40}
          py={8}
          textColor="#E3FF66"
          text="Download CV"
        />
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Full Name</p>
          <p>
            {applicant?.firstName} {applicant?.lastName}
          </p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Email</p>
          <p>{applicant?.user?.email}</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">University</p>
          <p>{applicant?.university?.universityNameEn}</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Faculty</p>
          <p>{applicant?.faculty?.facultyNameEn}</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Country</p>
          <p>{applicant?.country?.countryNameEn}</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">City</p>
          <p>{applicant?.city?.cityNameEn}</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Fields Of Work</p>
          <div className="w-full flex flex-row flex-wrap gap-x-2 gap-y-2">
            {applicant?.user?.userMajors?.map((item: any) => {
              return (
                <div className="p-3 flex items-center justify-center bg-dashboard_primary rounded-md">
                  <p className="text-white">{item?.major?.majorNameEn}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-xl">Skills</p>
          <div className="w-full flex flex-row flex-wrap gap-x-2 gap-y-2">
            {applicant?.user?.userSkills?.map((item: any) => {
              return (
                <div className="p-3 flex items-center justify-center bg-dashboard_primary rounded-md">
                  <p className="text-white">{item?.skill?.skillNameEn}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewApplication;
