import { Input } from "antd";
import { FC } from "react";
import { ErrorIcon } from "assets/icons";

type TextAreaProps = {
  placeholder: string;
  name: string;
  onChange: any;
  error?: any;
};

const TextArea: FC<TextAreaProps> = ({
  placeholder,
  name,
  onChange,
  error,
}) => {
  const { TextArea } = Input;
  return (
    <div className="w-full flex flex-col items-start gap-y-2">
      <TextArea
        autoSize={{ minRows: 6, maxRows: 6 }}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        className="w-full bg-transparent rounded-[15px] !caret-white text-white py-3 placeholder:!text-white !border-white !border"
      />
      {error && (
        <p className="text-error animate-pulse flex items-center gap-x-2">
          <img src={ErrorIcon} width={20} height={20} />
          {error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
