import
{
    LIST_VACANCIES_LOADING,
    LIST_VACANCIES_SUCCESS,
    LIST_VACANCIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listVacanciesEndpoint } from "api/apiUrls";

export const listVacanciesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_VACANCIES_LOADING, payload: true});
        const response = await listProtectedItemsService(listVacanciesEndpoint);
        dispatch({type: LIST_VACANCIES_SUCCESS, payload: response.data});
       
    }   
    catch (err) {
        dispatch({type: LIST_VACANCIES_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_VACANCIES_LOADING, payload: false});
    }
}