import { Select } from "antd";
import React, { FC } from "react";
import { DropdownIcon, ErrorIcon } from "assets/icons";
import { DefaultOptionType } from "antd/es/select";

type DropdownProps = {
  placeholder: string;
  options: DefaultOptionType[] | undefined;
  value?: any;
  onSelect?: any;
  error?: any;
};

const Dropdown: FC<DropdownProps> = ({
  placeholder,
  options,
  onSelect,
  error,
}) => {
  return (
    <div className="w-full flex flex-col items-start gap-y-2">
      <Select
        placeholder={placeholder}
        className="custom-dropdown !h-[3rem] w-full bg-transparent rounded-[15px] !caret-white text-white placeholder:!text-white !border-white !border"
        suffixIcon={<img src={DropdownIcon} alt="arrow down" />}
        options={options}
        onSelect={(value: any) => onSelect(value)}
      />
      {error && (
        <p className="text-error animate-pulse flex items-center gap-x-2">
          <img src={ErrorIcon} width={20} height={20} />
          {error}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
