import { HamburgerMenuIcon, Logo, CloseIcon } from "assets/icons";
import { useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import SignInModal from "../SignInModal/SignInModal";
import StepOne from "../SignUpModals/StepOne";
import StepTwo from "../SignUpModals/StepTwo";
import StepThree from "../SignUpModals/StepThree";
import { useStepTwoFormik } from "forms/stepTwoFormik";
import SocialLinks from "../SignUpModals/SocialLinks";
import ForgotPasswordOne from "../ForgotPasswordModals/ForgotPasswordOne";
import ForgotPasswordTwo from "../ForgotPasswordModals/ForgotPasswordTwo";
import { useForgotPasswordOne } from "forms/forgotPasswordForms/forgotPasswordOne";

const Navbar = () => {
  const [activeModal, setActiveModal] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const formik = useStepTwoFormik(setActiveModal);
  const forgotPasswordOneFormik = useForgotPasswordOne(setShowOtp);

  const [menuOpen, setMenuOpen] = useState(false);

  const menuAnimationControls = useAnimationControls();

  const handleMenuClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
      menuAnimationControls.start("hidden");
    } else {
      setMenuOpen(true);
      menuAnimationControls.start("visible");
    }
  };

  return (
    <>
      <div className="w-full flex justify-center">
        <nav className="w-full mx-auto max-w-[1600px] h-[80px] scrn-488:h-[88px] absolute bg-main scrn-488:rounded-bl-[50px] scrn-488:rounded-br-[50px] shadow-xl z-20 flex flex-row items-center justify-between px-8">
          {activeModal === "SIGNIN" && (
            <SignInModal setActiveModal={setActiveModal} />
          )}
          {activeModal === "SIGNUP" && (
            <StepOne setActiveModal={setActiveModal} />
          )}
          {activeModal === "STEPTWO" && (
            <StepTwo formik={formik} setActiveModal={setActiveModal} />
          )}
          {activeModal === "STEPTHREE" && (
            <StepThree otherData={formik} setActiveModal={setActiveModal} />
          )}
          {activeModal === "SOCIALLINKS" && (
            <SocialLinks setActiveModal={setActiveModal} />
          )}
          {activeModal === "FORGOT_PASSWORD_ONE" && (
            <ForgotPasswordOne
              formik={forgotPasswordOneFormik}
              showOtp={showOtp}
              setShowOtp={setShowOtp}
              setActiveModal={setActiveModal}
            />
          )}
          {activeModal === "FORGOT_PASSWORD_TWO" && (
            <ForgotPasswordTwo
              email={forgotPasswordOneFormik.values.email}
              setActiveModal={setActiveModal}
            />
          )}
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
          <div className="hidden scrn-488:flex flex-row items-center gap-x-5">
            <button
              onClick={() => setActiveModal("SIGNUP")}
              className="bg-[#DCDEE3] rounded-2xl flex items-center min-w-[120px] max-w-[120px] justify-center px-8 py-2"
            >
              Sign Up
            </button>
            <button
              onClick={() => setActiveModal("SIGNIN")}
              className="bg-secondary rounded-2xl flex items-center justify-center min-w-[120px] max-w-[120px] px-8 py-2"
            >
              Sign In
            </button>
          </div>
          <button
            onClick={handleMenuClick}
            className="w-8 h-8 block scrn-488:hidden"
          >
            {!menuOpen ? (
              <img src={HamburgerMenuIcon} alt="menu" />
            ) : (
              <img src={CloseIcon} alt="close" />
            )}
          </button>
        </nav>
      </div>
      <motion.div
        variants={{
          hidden: {
            width: 0,
            paddingBlock: "0rem",
            paddingInline: "0rem",
          },
          visible: {
            width: "100%",
            paddingBlock: "2rem",
            paddingInline: "2rem",
          },
        }}
        initial="hidden"
        animate={menuAnimationControls}
        className="h-screen mt-[80px] absolute bg-main z-20 flex flex-col items-center gap-y-6"
      >
        {menuOpen ? (
          <>
            <motion.button className="bg-[#DCDEE3] rounded-2xl flex items-center w-full justify-center px-8 py-2">
              Sign Up
            </motion.button>
            <motion.button className="bg-secondary rounded-2xl flex items-center justify-center w-full px-8 py-2">
              Sign In
            </motion.button>
          </>
        ) : (
          <></>
        )}
      </motion.div>
    </>
  );
};

export default Navbar;
