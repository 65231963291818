import
{   
    LIST_VAC_APP_LOADING,
    LIST_VAC_APP_SUCCESS,
    LIST_VAC_APP_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listVacancyApplicationsEndpoint } from "api/apiUrls";

export const listVacancyApplicationsAction = (vacancyId: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_VAC_APP_LOADING, payload: true});
        const response = await listProtectedItemsService(`${listVacancyApplicationsEndpoint}?vacancyId=${vacancyId}`);
        dispatch({type: LIST_VAC_APP_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: LIST_VAC_APP_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_VAC_APP_LOADING, payload: false});
    }
}