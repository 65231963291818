import
{
    CONFIRM_OTP_LOADING,
    CONFIRM_OTP_SUCCESS,
    CONFIRM_OTP_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { confirmOtpEndpoint } from "api/apiUrls";
import { notification } from "antd";

export const confirmOtpAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CONFIRM_OTP_LOADING, payload: true});
        const response = await createNewItemService(confirmOtpEndpoint, data, "json");
        dispatch({type: CONFIRM_OTP_SUCCESS, payload: response.data});
        if (response.status === 200) {
            notification.success({
                message: response.data.message
            });
        }
        else {
            notification.error({
                message: response.data.message
            });
        }
        callback && callback();
    }   
    catch (err) {
        dispatch({type: CONFIRM_OTP_FAILURE, payload: err});
    }
    finally {
        dispatch({type: CONFIRM_OTP_LOADING, payload: false});
    }
}