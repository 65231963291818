import { Input, Spin } from "antd";
import { LogoWhite, RoundedClose } from "assets/icons";
import { useForgotPasswordOne } from "forms/forgotPasswordForms/forgotPasswordOne";
import { useState } from "react";
import InputField from "../ui/InputField";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { forgotPasswordTwoAction } from "redux/actions/forgotPasswordActions/forgotPasswordTwoAction";

const ForgotPasswordOne = ({
  formik,
  setActiveModal,
  showOtp,
  setShowOtp,
}: {
  formik: any;
  setActiveModal: any;
  showOtp: boolean;
  setShowOtp: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch: any = useDispatch();

  const [otp, setOtp] = useState("");
  const [disableOtp, setDisableOtp] = useState(false);

  const { loading } = useSelector(
    (state: RootState) => state.forgotPasswordOneReducer
  );
  const { twoLoading } = useSelector(
    (state: RootState) => state.forgotPasswordTwoReducer
  );

  const handleConfirmOtp = async () => {
    await dispatch(
      forgotPasswordTwoAction(
        {
          forgotTypeId: 2,
          phoneNumber: "",
          email: formik.values.email,
          otp: otp,
        },
        () => {
          setActiveModal("FORGOT_PASSWORD_TWO");
        }
      )
    );
  };

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[15px]">
          <button
            onClick={() => setActiveModal(false)}
            className="w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-white font-bold text-2xl">Welcome Back!</p>
          <p className="text-white">Confirm your identity</p>
          <img src={LogoWhite} alt="logo" className="mt-4" />
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col items-start gap-y-6 px-4 pt-10"
          >
            <InputField
              name="email"
              placeholder="Enter your email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              errors={formik.errors.email}
            />
            <FormSubmitButton
              body="Send Code"
              disabled={showOtp}
              loading={loading}
            />
            {showOtp && (
              <div className="w-full flex flex-col items-center gap-y-4">
                <Input.OTP
                  length={4}
                  disabled={disableOtp}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e);
                    setDisableOtp(true);
                  }}
                />
                {disableOtp && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={() => {
                      setOtp("");
                      setDisableOtp(false);
                    }}
                    className="text-white text-sm"
                  >
                    Edit OTP
                  </a>
                )}
                <Spin spinning={twoLoading} wrapperClassName="full-width-spin">
                  <div className="w-full flex justify-center">
                    <button
                      onClick={handleConfirmOtp}
                      type="button"
                      className="w-[75%] h-[2.8rem] bg-[#DCDEE3] text-[14px] rounded-[15px] font-bold duration-100 hover:bg-main hover:text-secondary"
                    >
                      Confirm
                    </button>
                  </div>
                </Spin>
              </div>
            )}
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default ForgotPasswordOne;
