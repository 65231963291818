import Navbar from "components/Navbar/Navbar";
import Hero from "components/Hero/Hero";
import Subscription from "components/Subscription/Subscription";
import Partners from "components/Partners/Partners";
import Footer from "components/Footer/Footer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router";
import { DASHBOARD_HOME_PATH, DASHBOARD_PROFILE_PATH } from "Routes/paths";
import { useDispatch } from "react-redux";
import { getUserCompletionAction } from "redux/actions/getUserCompletionAction";
import { Spin } from "antd";

const Home = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { userCompletionLoading } = useSelector(
    (state: RootState) => state.getUserCompletionReducer
  );
  const { user } = useSelector((state: RootState) => state.stepOneReducer);

  useEffect(() => {
    dispatch(
      getUserCompletionAction((data: any) => {
        if (data?.isCompleted) {
          navigate(DASHBOARD_HOME_PATH);
        }
      })
    );
  }, [user]);

  return (
    <Spin wrapperClassName="full-width-spin" spinning={userCompletionLoading}>
      <div className="w-full h-full min-h-screen relative flex flex-col bg-white">
        <Navbar />
        <Hero />
        <Subscription />
        <Partners />
        <Footer />
      </div>
    </Spin>
  );
};

export default Home;
