import
{
    STEP_ONE_LOADING,
    STEP_ONE_SUCCESS,
    STEP_ONE_FAILURE,
    LOGIN_SUCCESS
}
from "redux/types";

const initialState = {
    stepOneLoading: false,
    user: null,
    token: null,
    errors: null
}

export const stepOneReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STEP_ONE_LOADING :
            return {...state, stepOneLoading: action.payload};
        case STEP_ONE_SUCCESS :
            return {...state, user: action.payload.data, token: action.payload.data.token};
        case LOGIN_SUCCESS :
            return {...state, token: action.payload.token, user: action.payload};
        case STEP_ONE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}