import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Autoplay } from "swiper/modules";
import { client1, client2, client3, client4 } from "assets/images";

const Partners = () => {
  return (
    <section className="w-full bg-white pb-10">
      <div className="w-full mx-auto max-w-[1440px] px-[25px] scrn-1025:px-0 flex flex-col items-center">
        <div className="w-full mx-auto max-w-[1250px] flex flex-col items-start gap-y-4">
          <h3 className="text-[32px] scrn-767:text-[48px] scrn-1025text-[56px] font-bold text-main">
            Intrn Partners
          </h3>
          <div className="w-full py-[50px] relative overflow-x-hidden">
            <Swiper
              loop
              className="w-full h-full flex items-center justify-center"
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
              slidesPerView={3}
              spaceBetween={0}
              autoplay={{
                delay: 2000,
              }}
            >
              <SwiperSlide className="flex items-center justify-center">
                <img src={client1} alt="client" />
              </SwiperSlide>
              <SwiperSlide className="flex items-center justify-center">
                <img src={client2} alt="client" />
              </SwiperSlide>
              <SwiperSlide className="flex items-center justify-center">
                <img src={client3} alt="client" />
              </SwiperSlide>
              <SwiperSlide className="flex items-center justify-center">
                <img src={client4} alt="client" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
