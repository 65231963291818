import
{
    USER_COMPLETION_LOADING,
    USER_COMPLETION_SUCCESS,
    USER_COMPLETION_FAILURE
}
from "redux/types";

const initialState = {
    userCompletionLoading: false,
    userCompletion: null,
    errors: null
}

export const getUserCompletionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_COMPLETION_LOADING :
            return {...state, userCompletionLoading: action.payload};
        case USER_COMPLETION_SUCCESS :
            return {...state, userCompletion: action.payload};
        case USER_COMPLETION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}