import
{
    PASSWORD_TWO_LOADING,
    PASSWORD_TWO_SUCCESS,
    PASSWORD_TWO_FAILURE
}
from "redux/types";

const initialState = {
    twoLoading: false,
    data: null,
    errros: null
}

export const forgotPasswordTwoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PASSWORD_TWO_LOADING :
            return {...state, twoLoading: action.payload};
        case PASSWORD_TWO_SUCCESS :
            return {...state, data: action.payload};
        case PASSWORD_TWO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}