import { Spin } from "antd";
import { RoundedClose } from "assets/icons";
import InputField from "../ui/InputField";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useSocialLinksFormik } from "forms/socialLinksFormik";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const SocialLinks = ({ setActiveModal }: { setActiveModal: any }) => {
  const formik = useSocialLinksFormik(setActiveModal);

  const { stepThreeLoading } = useSelector(
    (state: RootState) => state.stepThreeReducer
  );

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-0">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[8px] lg:gap-[15px] z-10">
          <button
            onClick={() => setActiveModal("")}
            className="block lg:hidden w-[2rem] h-[2rem] self-end"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <button
            onClick={() => setActiveModal("")}
            className="hidden lg:block w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-xl text-white font-bold">
            fill social media links
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col gap-y-4"
          >
            <InputField
              name="facebookLink"
              placeholder="Facebook Link"
              type="text"
              value={formik.values.facebookLink}
              onChange={formik.handleChange}
              errors={formik.errors.facebookLink}
            />
            <InputField
              name="instagramLink"
              placeholder="Instagram Link"
              type="text"
              value={formik.values.instagramLink}
              onChange={formik.handleChange}
              errors={formik.errors.instagramLink}
            />
            <InputField
              name="webSite"
              placeholder="Website Link"
              type="text"
              value={formik.values.webSite}
              onChange={formik.handleChange}
              errors={formik.errors.webSite}
            />
            <InputField
              name="xLink"
              placeholder="X Link"
              type="text"
              value={formik.values.xLink}
              onChange={formik.handleChange}
              errors={formik.errors.xLink}
            />
            <InputField
              name="linkedInLink"
              placeholder="Linkedin Link"
              type="text"
              value={formik.values.linkedInLink}
              onChange={formik.handleChange}
              errors={formik.errors.linkedInLink}
            />
            <FormSubmitButton
              body="Create Account"
              loading={stepThreeLoading}
            />
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default SocialLinks;
