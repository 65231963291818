import { notification, Spin, Upload, UploadProps } from "antd";
import { ErrorIcon, RoundedClose } from "assets/icons";
import Dropdown from "../ui/Dropdown";
import { EMPLOYEES_COUNT_OPTIONS } from "../../constants";
import { generateYears } from "utils/functions";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload";
import { useState } from "react";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useStepThreeFormik } from "forms/stepThreeFormik";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const StepThree = ({
  otherData,
  setActiveModal,
}: {
  otherData: any;
  setActiveModal: any;
}) => {
  const [taxCard, setTaxCard] = useState<UploadFile[]>([]);
  const [commercialRecord, setCommercialRecord] = useState<UploadFile[]>([]);

  const formik = useStepThreeFormik(otherData, setActiveModal);

  const props: UploadProps = {
    name: "file",
    onRemove: (file: any) => {
      const index = taxCard.indexOf(file);
      const newFileList = taxCard.slice();
      newFileList.splice(index, 1);
      setTaxCard(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      if (taxCard.length === 1) {
        notification.error({
          message: "You can only place 1 file",
        });
        return false;
      }
      const validExtensions = ["pdf", "docx"];
      const fileExtension = file.name.split(".").pop();
      if (!validExtensions.includes(fileExtension ?? "")) {
        notification.error({
          message: "File extension must be .pdf, or .docx",
        });
        return false;
      }
      formik.setFieldValue("TaxCard", file);
      setTaxCard([...taxCard, file]);
      return false;
    },
    fileList: taxCard,
  };

  const commercial: UploadProps = {
    name: "file",
    onRemove: (file: any) => {
      const index = commercialRecord.indexOf(file);
      const newFileList = commercialRecord.slice();
      newFileList.splice(index, 1);
      setCommercialRecord(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      if (commercialRecord.length === 1) {
        notification.error({
          message: "You can only place 1 file",
        });
        return false;
      }
      const validExtensions = ["pdf", "docx"];
      const fileExtension = file.name.split(".").pop();
      if (!validExtensions.includes(fileExtension ?? "")) {
        notification.error({
          message: "File extension must be .pdf, or .docx",
        });
        return false;
      }
      formik.setFieldValue("CommercialRecord", file);
      setCommercialRecord([...commercialRecord, file]);
      return false;
    },
    fileList: commercialRecord,
  };

  const yearsDropdownOptions = generateYears(1900)
    .reverse()
    .map((item: number) => {
      return {
        label: item.toString(),
        value: item,
      };
    });

  const { stepTwoLoading } = useSelector(
    (state: RootState) => state.stepTwoReducer
  );

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[8px] lg:gap-[15px]">
          <button
            onClick={() => setActiveModal(false)}
            className="block lg:hidden w-[2rem] h-[2rem] self-end"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <button
            onClick={() => setActiveModal(false)}
            className="hidden lg:block w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-xl text-white font-bold">
            fill company information
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col gap-y-4"
          >
            <Dropdown
              placeholder="Number of Employees"
              options={EMPLOYEES_COUNT_OPTIONS}
              onSelect={(value: any) =>
                formik.setFieldValue("CountOfEmployees", value)
              }
              error={formik.errors.CountOfEmployees}
            />
            <Dropdown
              placeholder="Foundation Year"
              options={yearsDropdownOptions}
              onSelect={(value: any) =>
                formik.setFieldValue("FoundationYear", value)
              }
              error={formik.errors.FoundationYear}
            />
            <div className="w-full flex flex-col items-start gap-y-2">
              <label className="text-white font-semibold">Tax Card</label>
              <Upload {...props}>
                <button
                  type="button"
                  className="border border-white rounded-[15px] !h-[3rem] flex items-center justify-center px-4 py-3 text-white gap-x-4"
                >
                  <p>Click to Upload</p>
                  <UploadOutlined />
                </button>
              </Upload>
              {formik.errors.TaxCard && (
                <p className="text-error flex items-center gap-x-2">
                  <img src={ErrorIcon} width={20} height={20} />
                  {formik.errors.TaxCard.toString()}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <label className="text-white font-semibold">
                Commercial Record
              </label>
              <Upload {...commercial}>
                <button
                  type="button"
                  className="border border-white rounded-[15px] !h-[3rem] flex items-center justify-center px-4 py-3 text-white gap-x-4"
                >
                  <p>Click to Upload</p>
                  <UploadOutlined />
                </button>
              </Upload>
              {formik.errors.CommercialRecord && (
                <p className="text-error flex items-center gap-x-2">
                  <img src={ErrorIcon} width={20} height={20} />
                  {formik.errors.CommercialRecord.toString()}
                </p>
              )}
            </div>
            <FormSubmitButton body="Next" loading={stepTwoLoading} />
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default StepThree;
