import { Spin } from "antd";
import { FC } from "react";

type FormSubmitButtonProps = {
  body: string;
  loading?: boolean;
  disabled?: boolean;
};

const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  body,
  loading,
  disabled,
}) => {
  return (
    <Spin wrapperClassName="full-width-spin" spinning={loading}>
      <button
        type="submit"
        disabled={disabled}
        style={{
          opacity: disabled ? 0.7 : 1,
        }}
        className={`w-full h-[2.8rem] bg-[#DCDEE3] text-[14px] rounded-[15px] font-bold duration-100 ${
          disabled ? "" : "hover:text-secondary hover:bg-main"
        }`}
      >
        {body}
      </button>
    </Spin>
  );
};

export default FormSubmitButton;
