import
{
    LIST_APPLICATION_LOADING,
    LIST_APPLICATION_SUCCESS,
    LIST_APPLICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listSingleApplicationEndpoint } from "api/apiUrls";
import { listApplicantAction } from "./listApplicantAction";
import store from "redux/store";

export const listSingleApplicationAction = (id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_APPLICATION_LOADING, payload: true});
        const response = await listProtectedItemsService(`${listSingleApplicationEndpoint}?id=${id}`);
        dispatch({type: LIST_APPLICATION_SUCCESS, payload: response.data});
        await store.dispatch(listApplicantAction(response.data?.user?.userId));
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: LIST_APPLICATION_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_APPLICATION_LOADING, payload: false});
    }
}