import Footer from "components/Footer/Footer";
import SubscriptionCard from "components/SubscriptionPlansComponents/SubscriptionCard";
import { useEffect, useRef, useState } from "react";
import { CheckIcon } from "assets/icons";
import {
  cvUnlocksFeatures,
  enhancedVisibility,
  goldPlan,
  multiPostDiscounts,
  payPerPostFeatures,
  positionLevel,
  renewalOrExtension,
  sliverPlan,
} from "constants/index";
import AdditionalFeaturesButton, {
  AdditionalFeaturesButtonProps,
} from "components/SubscriptionPlansComponents/AdditionalFeaturesButton";
import CvUnlocks from "components/SubscriptionPlansComponents/CvUnlocks";
import AdditionalPosts from "components/SubscriptionPlansComponents/AdditionalPosts";
import RenewalOrExtension from "components/SubscriptionPlansComponents/RenewalOrExtension";
import Navbar from "components/Navbar/Navbar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listSubscriptionPlansAction } from "redux/actions/listSubscriptionPlansAction";
import { Spin } from "antd";

const SubscriptionPlans = () => {
  const dispatch: any = useDispatch();

  const element = useRef<any>();

  const [totalPrice, setTotalPrice] = useState<number>(500);

  const [activePop, setActivePop] = useState("");

  const [cvUnlocks, setCvUnlocks] = useState(-1);
  const [additionalPosts, setAdditionalPosts] = useState(-1);
  const [renewalOrExtensionState, setRenewalOrExtensionState] = useState(-1);

  const { plansLoading, plans } = useSelector(
    (state: RootState) => state.listSubscriptionPlansReducer
  );

  useEffect(() => {
    dispatch(listSubscriptionPlansAction());
  }, []);

  return (
    <div className="w-full bg-white">
      <Navbar />
      <div className="w-full max-w-[1600px] mx-auto flex flex-col items-center">
        <Spin wrapperClassName="full-width-spin" spinning={plansLoading}>
          <div className="w-full pt-[105px] pb-[105px] h-full flex flex-col items-center">
            <h1 className="font-Avenir_bold text-[20px] sm:text-[24px] md:text-[32px] lg:text-[42px] text-[#142644] text-balance">
              Find the Perfect Plan for You
            </h1>
            <div className="mt-[30px] flex flex-col md:flex-row gap-x-[13px]">
              <p className="font-Avenir_demi text-[16px] sm:text-[18px] md:text-[24px] text-[#142644]">
                You want to pay per post?
              </p>
              <button
                onClick={() => {
                  element?.current.scrollIntoView();
                }}
                className="rounded-[15px] px-16 py-3 bg-[#DCDEE3]"
              >
                <p className="font-Avenir_demi text-[#142644] text-[14px]">
                  Check Now
                </p>
              </button>
            </div>
            <div className="w-full mt-[45px] flex flex-col lg:flex-row gap-x-[32px] gap-y-[32px] lg:items-start items-center justify-center">
              {plans
                ?.filter((_: any, idx: number) => !(idx > 1))
                .map((plan: any, idx: number) => (
                  <div className="w-[95%] sm:w-[80%] md:w-[60%] lg:w-[40%]">
                    <SubscriptionCard
                      id={idx + 1}
                      planName={plan?.nameEn}
                      planDescription={plan?.description}
                      features={plan?.subscriptionPlanFeatures}
                      pricingIntervals={[
                        {
                          totalAmount: plan?.annualPrice?.toLocaleString(),
                          per: 1,
                          value: "Year",
                          discountAppliedText: `${Math.round(
                            plan?.annualPrice / 12
                          ).toLocaleString()} EGP / month, ${
                            100 -
                            Math.round(
                              (Math.round(plan?.annualPrice) /
                                12 /
                                plan?.monthlyPrice) *
                                100
                            )
                          }% discount applied`,
                          uniqueIdentifier: "8c4BNxABHh2c6IiuikaPSqrVjKMXd8TP",
                        },
                        {
                          totalAmount: plan?.quarterlyPrice?.toLocaleString(),
                          per: 3,
                          value: "Month",
                          discountAppliedText: `${(
                            Math.round(plan?.quarterlyPrice) / 3
                          ).toLocaleString()} EGP / month, ${
                            100 -
                            Math.round(
                              (Math.round(plan?.quarterlyPrice) /
                                3 /
                                plan?.monthlyPrice) *
                                100
                            )
                          }% discount applied`,
                          uniqueIdentifier: "8c4BNxABHh2c6IiuikaPSqrVjKMXd8TP",
                        },
                        {
                          totalAmount: plan?.monthlyPrice,
                          per: 1,
                          value: "Month",
                          uniqueIdentifier: "8c4BNxABHh2c6IiuikaPSqrVjKMXd8TP",
                        },
                      ]}
                      setActivePop={setActivePop}
                    />
                  </div>
                ))}
            </div>

            <div
              ref={element}
              className="mt-[64px] flex flex-col px-3 py-3 rounded-[15px] w-full md:w-[83%]"
            >
              <div className="w-full flex flex-col lg:flex-row justify-between gap-x-6">
                <div className="w-[95%] lg:w-1/2 flex flex-col gap-y-3 shadow-shadow_main p-4 rounded-[15px]">
                  <p className="text-[#142644] text-[16px] md:text-[20px] font-Avenir_demi">
                    Pay Per Post
                  </p>
                  <p className="font-Avenir_bold text-[16px] md:text-[20px] text-[#142644]">
                    500 EGP Per Post
                  </p>
                  <div className="w-full flex flex-col items-start gap-y-4">
                    {payPerPostFeatures.map((item: string) => {
                      return (
                        <div className="w-full flex flex-row gap-x-2 items-center">
                          <img src={CheckIcon} alt="check-icon" />
                          <p className="font-Avenir_demi text-[12px] md:text-[16px] text-[#142644]">
                            {item}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-10 w-[95%] lg:w-1/2 flex flex-col items-center md:items-start gap-y-3">
                  <p className="font-Avenir_demi text-[#142644] text-balance text-[16px] md:text-[20px]">
                    You can also add more with extra fees
                  </p>
                  <div className="w-full flex flex-col md:flex-row items-center justify-between">
                    <p className="font-Avenir_demi text-[#142644] text-[16px]">
                      CV Unlocks
                    </p>
                    <div className="flex flex-row items-center gap-x-3">
                      {cvUnlocksFeatures.map(
                        (item: AdditionalFeaturesButtonProps, idx: number) => {
                          return (
                            <CvUnlocks
                              {...item}
                              value={idx}
                              siblingAmount={
                                idx !== 0
                                  ? cvUnlocksFeatures.at(idx - 1)?.amountAdd
                                  : cvUnlocksFeatures.at(idx + 1)?.amountAdd
                              }
                              setState={setTotalPrice}
                              selected={cvUnlocks}
                              setSelected={setCvUnlocks}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row items-center justify-between">
                    <p className="font-Avenir_demi text-[#142644] text-[16px]">
                      Multi-Post Discounts
                    </p>
                    <div className="pt-5 flex flex-row items-start gap-x-3">
                      {multiPostDiscounts.map(
                        (item: AdditionalFeaturesButtonProps, idx: any) => {
                          return (
                            <AdditionalPosts
                              {...item}
                              value={idx}
                              siblingAmount={
                                idx !== 0
                                  ? multiPostDiscounts.at(idx - 1)?.amountAdd
                                  : multiPostDiscounts.at(idx + 1)?.amountAdd
                              }
                              setState={setTotalPrice}
                              selected={additionalPosts}
                              setSelected={setAdditionalPosts}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row items-center justify-between">
                    <p className="font-Avenir_demi text-[#142644] text-[16px]">
                      Renewal or Extension
                    </p>
                    <div className="flex flex-row items-center gap-x-3">
                      {renewalOrExtension.map(
                        (item: AdditionalFeaturesButtonProps, idx: number) => {
                          return (
                            <RenewalOrExtension
                              {...item}
                              value={idx}
                              setState={setTotalPrice}
                              selected={renewalOrExtensionState}
                              setSelected={setRenewalOrExtensionState}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row items-center justify-between">
                    <p className="font-Avenir_demi text-[#142644] text-[16px]">
                      Position Level
                    </p>
                    <div className="flex flex-row items-center gap-x-3">
                      {positionLevel.map(
                        (item: AdditionalFeaturesButtonProps) => {
                          return (
                            <AdditionalFeaturesButton
                              {...item}
                              setState={setTotalPrice}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row items-center md:items-start justify-between">
                    <p className="pt-10 font-Avenir_demi text-[#142644] text-[16px]">
                      Enhanced Visibility
                    </p>
                    <div className="pt-5 flex flex-row items-start gap-x-3">
                      {enhancedVisibility.map(
                        (item: AdditionalFeaturesButtonProps) => {
                          return (
                            <AdditionalFeaturesButton
                              {...item}
                              setState={setTotalPrice}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 w-full bg-pro_bg rounded-[15px] flex flex-row items-center justify-between px-4 py-3">
                <div className="flex flex-row items-center gap-x-3">
                  <p className="font-Avenir_regular font-extralight text-white text-[16px] md:text-[20px] inline">
                    Pay Per Post Total Price{" "}
                    <p className="font-Avenir_bold text-white text-[20px] inline">
                      {totalPrice} EGP
                    </p>
                  </p>
                </div>
                <button
                  type="button"
                  className="rounded-[15px] bg-white px-6 py-2"
                >
                  <p className="font-Avenir_bold text-black text-[16px]">
                    Subscribe
                  </p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </div>

      <Footer />
    </div>
  );
};
export default SubscriptionPlans;
