import
{
    LIST_VACANCY_LOADING,
    LIST_VACANCY_SUCCESS,
    LIST_VACANCY_FAILURE
}
from "redux/types";

const initialState = {
    vacancyLoading: false,
    vacancy: null,
    errors: null
}

export const listSingleVacancyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_VACANCY_LOADING :
            return {...state, vacancyLoading: action.payload};
        case LIST_VACANCY_SUCCESS :
            return {...state, vacancy: action.payload};
        case LIST_VACANCY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}