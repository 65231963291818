import { Spin } from "antd";
import { LogoWhite, RoundedClose } from "assets/icons";
import { useForgotPasswordTwo } from "forms/forgotPasswordForms/forgotPasswordTwo";
import InputField from "../ui/InputField";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const ForgotPasswordTwo = ({
  email,
  setActiveModal,
}: {
  email: string;
  setActiveModal: any;
}) => {
  const formik = useForgotPasswordTwo(setActiveModal, email);

  const { loading } = useSelector(
    (state: RootState) => state.forgotPasswordThreeReducer
  );

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[15px]">
          <button
            onClick={() => setActiveModal(false)}
            className="w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-white font-bold text-2xl">Welcome Back!</p>
          <p className="text-white">Reset your password</p>
          <img src={LogoWhite} alt="logo" className="mt-4" />
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col items-start gap-y-6 px-4 pt-10"
          >
            <InputField
              name="newPassword"
              placeholder="Enter your new password"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              errors={formik.errors.newPassword}
            />
            <InputField
              name="confirmNewPassword"
              placeholder="Confirm new password"
              type="password"
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              errors={formik.errors.confirmNewPassword}
            />
            <FormSubmitButton
              body="Reset Password"
              disabled={false}
              loading={loading}
            />
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default ForgotPasswordTwo;
