import
{
    CONFIRM_OTP_LOADING,
    CONFIRM_OTP_SUCCESS,
    CONFIRM_OTP_FAILURE
}
from "redux/types";

const initialState = {
    confirmLoading: false,
    success: null,
    errors: null
}

export const confirmOtpReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CONFIRM_OTP_LOADING :
            return {...state, confirmLoading: action.payload};
        case CONFIRM_OTP_SUCCESS :
            return {...state, success: action.payload.success};
        case CONFIRM_OTP_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}