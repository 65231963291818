import
{
    UPDATE_APPLICATION_STATUS_LOADING,
    UPDATE_APPLICATION_STATUS_SUCCESS,
    UPDATE_APPLICATION_STATUS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewProtectedItemService } from "services/createNewProtectedItemService";
import { updateApplicationStatusEndpoint } from "api/apiUrls";

export const updateApplicationStatusAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_APPLICATION_STATUS_LOADING, payload: true});
        const response = await createNewProtectedItemService(`${updateApplicationStatusEndpoint}?applicationId=${data?.applicationId}&newStatus=${data?.newStatus}`, {}, "json");
        dispatch({type: UPDATE_APPLICATION_STATUS_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: UPDATE_APPLICATION_STATUS_FAILURE, payload: err});
    }
    finally {
        dispatch({type: UPDATE_APPLICATION_STATUS_LOADING, payload: false});
    }
}