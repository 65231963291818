import { useFormik } from "formik";
import * as yup from "yup";
import ForgotPasswordOneModel from "models/ForgotPasswordOneModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { forgotPasswordOneAction } from "redux/actions/forgotPasswordActions/forgotPasswordOneAction";

const useForgotPasswordOne = (setShowOtp: React.Dispatch<React.SetStateAction<boolean>>) => {
    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: ''
        },  
        validationSchema: yup.object({
            email: yup.string().required(DEFAULT_ERROR_MSG).email('please enter a valid email'),
        }),
        onSubmit: async (values: ForgotPasswordOneModel) => {
            await dispatch(forgotPasswordOneAction({
                phoneNumber : "",
                email: values.email,
                forgotTypeId: 2
            }, () => {
                setShowOtp(true);
            }));
        }
    });

    return formik;
}

export {
    useForgotPasswordOne
}