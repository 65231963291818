import
{
    USER_DATA_LOADING,
    USER_DATA_SUCCESS,
    USER_DATA_FAILURE
}
from "redux/types";

const initialState = {
    userDataLoading: false,
    userData: null,
    errors: null
}

export const getUserDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_DATA_LOADING :
            return {...state, userDataLoading: action.payload};
        case USER_DATA_SUCCESS :
            return {...state, userData: action.payload};
        case USER_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}