import {
  DASHBOARD_APPLICATIONS_PATH,
  DASHBOARD_CREATE_VACANCY_PATH,
  DASHBOARD_DOWNLOAD_CV_PATH,
  DASHBOARD_HOME_PATH,
  DASHBOARD_PROFILE_PATH,
  DASHBOARD_UPDATE_VACANCY_PATH,
  DASHBOARD_VACANCIES_PATH,
  DASHBOARD_VIEW_APPLICATION_PATH,
  HOME_PATH,
  SUBSCRIPTION_PLANS_PATH,
} from "Routes/paths";
import Home from "screens/Home/Home";
import DashboardHome from "screens/Dashboard/Home/DashboardHome";
import DashboardParent from "screens/Dashboard/DashboardParent";
import Profile from "screens/Dashboard/Profile/Profile";
import Vacancies from "screens/Dashboard/Vacancies/Vacancies";
import CreateVacancy from "screens/Dashboard/Vacancies/CreateVacancy";
import EditVacancy from "screens/Dashboard/Vacancies/EditVacancy";
import Applications from "screens/Dashboard/Applications/Applications";
import ViewApplication from "screens/Dashboard/Applications/ViewApplication";
import CvDownloader from "screens/Dashboard/Applications/CvDownloader";
import SubscriptionPlans from "screens/SubscriptionPlans/SubscriptionPlans";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: DASHBOARD_HOME_PATH,
    element: <DashboardHome />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_PROFILE_PATH,
    element: <Profile />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_VACANCIES_PATH,
    element: <Vacancies />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_CREATE_VACANCY_PATH,
    element: <CreateVacancy />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_UPDATE_VACANCY_PATH,
    element: <EditVacancy />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_APPLICATIONS_PATH,
    element: <Applications />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_VIEW_APPLICATION_PATH,
    element: <ViewApplication />,
    parent: <DashboardParent />,
  },
  {
    path: DASHBOARD_DOWNLOAD_CV_PATH,
    element: <CvDownloader />,
  },
  {
    path: SUBSCRIPTION_PLANS_PATH,
    element: <SubscriptionPlans />,
  },
];
