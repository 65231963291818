import { Spin } from "antd";
import { RoundedClose } from "assets/icons";
import TextArea from "../ui/TextArea";
import Dropdown from "../ui/Dropdown";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useDropdown } from "hooks/useDropdown";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { listCitiesAction } from "redux/actions/utilActions/listCitiesAction";
import { useStepTwoFormik } from "forms/stepTwoFormik";

const StepTwo = ({
  formik,
  setActiveModal,
}: {
  formik: any;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch: any = useDispatch();

  const { majorsLoading } = useSelector(
    (state: RootState) => state.listMajorsReducer
  );
  const { countriesLoading } = useSelector(
    (state: RootState) => state.listCountriesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { majorsDropdown, countriesDropdown } = useDropdown();

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item?.cityNameEn,
      value: item?.cityId,
    };
  });

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative lg:w-[410px] p-[30px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[15px]">
          <button
            onClick={() => setActiveModal("")}
            className="block lg:hidden w-[2rem] h-[2rem] self-end"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <button
            onClick={() => setActiveModal("")}
            className="hidden lg:block w-[2rem] h-[2rem] absolute right-5"
          >
            <img src={RoundedClose} alt="close" />
          </button>
          <p className="text-xl text-white font-bold">
            fill company information
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col gap-y-4"
          >
            <TextArea
              placeholder="Bio"
              name="Bio"
              onChange={formik.handleChange}
              error={formik.errors.Bio}
            />
            <Spin spinning={majorsLoading} wrapperClassName="full-width-spin">
              <Dropdown
                options={majorsDropdown}
                placeholder="Company Field"
                onSelect={(value: any) => {
                  formik.setFieldValue("CompanyFieldId", value);
                }}
                error={formik.errors.MajorId}
              />
            </Spin>
            <Spin
              spinning={countriesLoading}
              wrapperClassName="full-width-spin"
            >
              <Dropdown
                options={countriesDropdown}
                placeholder="Country"
                onSelect={(value: any) => {
                  formik.setFieldValue("CountryId", value);
                  dispatch(listCitiesAction(value));
                }}
                error={formik.errors.CountryId}
              />
            </Spin>
            <Spin spinning={citiesLoading} wrapperClassName="full-width-spin">
              <Dropdown
                options={citiesDropdown}
                placeholder="City"
                onSelect={(value: any) => formik.setFieldValue("CityId", value)}
                error={formik.errors.CityId}
              />
            </Spin>
            <FormSubmitButton body="Next" loading={false} />
          </form>
        </div>
      </Spin>
    </div>
  );
};

export default StepTwo;
