//Auth Types
export const AUTH_LOADING = "AUTH_LOADING";
export const LOG_IN = "LOG_IN";
export const SET_USER = "SET_USER";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const TRY_AGAIN = "TRY_AGAIN";
export const LOG_OUT = "LOG_OUT";
export const RESET_AUTH = "RESET_AUTH";

// Login Types
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// Step One Types
export const STEP_ONE_LOADING = "STEP_ONE_LOADING";
export const STEP_ONE_SUCCESS = "STEP_ONE_SUCCESS";
export const STEP_ONE_FAILURE = "STEP_ONE_FAILURE";

// Step Two Types
export const STEP_TWO_LOADING = "STEP_TWO_LOADING";
export const STEP_TWO_SUCCESS = "STEP_TWO_SUCCESS";
export const STEP_TWO_FAILURE = "STEP_TWO_FAILURE";

// Step Three Types
export const STEP_THREE_LOADING = "STEP_THREE_LOADING";
export const STEP_THREE_SUCCESS = "STEP_THREE_SUCCESS";
export const STEP_THREE_FAILURE = "STEP_THREE_FAILURE";

// Generate OTP Types
export const GEN_OTP_LOADING = "GEN_OTP_LOADING";
export const GEN_OTP_SUCCESS = "GEN_OTP_SUCCESS";
export const GEN_OTP_FAILURE = "GEN_OTP_FAILURE";

// Confirm OTP Types
export const CONFIRM_OTP_LOADING = "CONFIRM_OTP_LOADING";
export const CONFIRM_OTP_SUCCESS = "CONFIRM_OTP_SUCCESS";
export const CONFIRM_OTP_FAILURE = "CONFIRM_OTP_FAILURE";

// List Major Types
export const LIST_MAJORS_LOADING = "LIST_MAJORS_LOADING";
export const LIST_MAJORS_SUCCESS = "LIST_MAJORS_SUCCESS";
export const LIST_MAJORS_FAILURE = "LIST_MAJORS_FAILURE";

// List Countries Types
export const LIST_COUNTRIES_LOADING = "LIST_COUNTRIES_LOADING";
export const LIST_COUNTRIES_SUCCESS = "LIST_COUNTRIES_SUCCESS";
export const LIST_COUNTRIES_FAILURE = "LIST_COUNTRIES_FAILURE";

// List Cities Types
export const LIST_CITIES_LOADING = "LIST_CITIES_LOADING";
export const LIST_CITIES_SUCCESS = "LIST_CITIES_SUCCESS";
export const LIST_CITIES_FAILURE = "LIST_CITIES_FAILURE";

// Get User Data Types
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAILURE = "USER_DATA_FAILURE";

// Get User Completion
export const USER_COMPLETION_LOADING = "USER_COMPLETION_LOADING";
export const USER_COMPLETION_SUCCESS = "USER_COMPLETION_SUCCESS";
export const USER_COMPLETION_FAILURE = "USER_COMPLETION_FAILURE";

// List Areas Types
export const LIST_AREAS_LOADING = "LIST_AREAS_LOADING";
export const LIST_AREAS_SUCCESS = "LIST_AREAS_SUCCESS";
export const LIST_AREAS_FAILURE = "LIST_AREAS_FAILURE";

// Create Vacancy Types
export const CREATE_VACANCY_LOADING = "CREATE_VACANCY_LOADING";
export const CREATE_VACANCY_SUCCESS = "CREATE_VACANCY_SUCCESS";
export const CREATE_VACANCY_FAILURE = "CREATE_VACANCY_FAILURE";

// List Skills Types
export const LIST_SKILLS_LOADING = "LIST_SKILLS_LOADING";
export const LIST_SKILLS_SUCCESS = "LIST_SKILLS_SUCCESS";
export const LIST_SKILLS_FAILURE = "LIST_SKILLS_FAILURE";

// Update Profile Types
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// List Vacancies Types
export const LIST_VACANCIES_LOADING = "LIST_VACANCIES_LOADING";
export const LIST_VACANCIES_SUCCESS = "LIST_VACANCIES_SUCCESS";
export const LIST_VACANCIES_FAILURE = "LIST_VACANCIES_FAILURE";

// Update Vacancy Types
export const UPDATE_VACANCY_LOADING = "UPDATE_VACANCY_LOADING";
export const UPDATE_VACANCY_SUCCESS = "UPDATE_VACANCY_SUCCESS";
export const UPDATE_VACANCY_FAILURE = "UPDATE_VACANCY_FAILURE";

// List Single Vacancy Types
export const LIST_VACANCY_LOADING = "LIST_VACANCY_LOADING";
export const LIST_VACANCY_SUCCESS = "LIST_VACANCY_SUCCESS";
export const LIST_VACANCY_FAILURE = "LIST_VACANCY_FAILURE";

// List Vacancy Applications Types
export const LIST_VAC_APP_LOADING = "LIST_VAC_APP_LOADING";
export const LIST_VAC_APP_SUCCESS = "LIST_VAC_APP_SUCCESS";
export const LIST_VAC_APP_FAILURE = "LIST_VAC_APP_FAILURE";

// List Application Types
export const LIST_APPLICATION_LOADING = "LIST_APPLICATION_LOADING";
export const LIST_APPLICATION_SUCCESS = "LIST_APPLICATION_SUCCESS";
export const LIST_APPLICATION_FAILURE = "LIST_APPLICATION_FAILURE";

// List Application Types
export const LIST_APPLICANT_LOADING = "LIST_APPLICANT_LOADING";
export const LIST_APPLICANT_SUCCESS = "LIST_APPLICANT_SUCCESS";
export const LIST_APPLICANT_FAILURE = "LIST_APPLICANT_FAILURE";

// Update Application Status
export const UPDATE_APPLICATION_STATUS_LOADING = "UPDATE_APPLICATION_STATUS_LOADING";
export const UPDATE_APPLICATION_STATUS_SUCCESS = "UPDATE_APPLICATION_STATUS_SUCCESS";
export const UPDATE_APPLICATION_STATUS_FAILURE = "UPDATE_APPLICATION_STATUS_FAILURE";

// Delete Vacancy Types
export const DELETE_VACANCY_LOADING = "DELETE_VACANCY_LOADING";
export const DELETE_VACANCY_SUCCESS = "DELETE_VACANCY_SUCCESS";
export const DELETE_VACANCY_FAILURE = "DELETE_VACANCY_FAILURE";

// Forgot Password One Types
export const PASSWORD_ONE_LOADING = "PASSWORD_ONE_LOADING";
export const PASSWORD_ONE_SUCCESS = "PASSWORD_ONE_SUCCESS";
export const PASSWORD_ONE_FAILURE = "PASSWORD_ONE_FAILURE";

// Forgot Password Two Types
export const PASSWORD_TWO_LOADING = "PASSWORD_TWO_LOADING";
export const PASSWORD_TWO_SUCCESS = "PASSWORD_TWO_SUCCESS";
export const PASSWORD_TWO_FAILURE = "PASSWORD_TWO_FAILURE";

// Forgot Password Three Types
export const PASSWORD_THREE_LOADING = "PASSWORD_THREE_LOADING";
export const PASSWORD_THREE_SUCCESS = "PASSWORD_THREE_SUCCESS";
export const PASSWORD_THREE_FAILURE = "PASSWORD_THREE_FAILURE";

// List Subscription Plans Types
export const LIST_PLANS_LOADING = "LIST_PLANS_LOADING";
export const LIST_PLANS_SUCCESS = "LIST_PLANS_SUCCESS";
export const LIST_PLANS_FAILURE = "LIST_PLANS_FAILURE";