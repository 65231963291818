import { useFormik } from "formik"
import * as yup from "yup";
import CreateVacancyModel from "models/CreateVacancyModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { createVacancyAction } from "redux/actions/vacancyActions/createVacancyAction";
import { notification } from "antd";
import { DASHBOARD_VACANCIES_PATH } from "Routes/paths";
import { useNavigate } from "react-router";

const useCreateVacancyFormik = () => {
    const dispatch: any = useDispatch();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            areaId: '',
            cityId: '',
            countryId: '',
            deadline: '',
            description: '',
            majorId: '',
            requirements: '',
            responsibilities: '',
            skillIds: '',
            title: '',
            vacancyLevelId: '',
            VacancyWorkPlaceId: ''
        },
        validationSchema: yup.object({
            cityId: yup.string().required(DEFAULT_ERROR_MSG),
            countryId: yup.string().required(DEFAULT_ERROR_MSG),
            title: yup.string().required(DEFAULT_ERROR_MSG),
            requirements: yup.string().required(DEFAULT_ERROR_MSG).notOneOf(['<p></p>'], DEFAULT_ERROR_MSG),
            responsibilities: yup.string().required(DEFAULT_ERROR_MSG).notOneOf(['<p></p>'], DEFAULT_ERROR_MSG),
            vacancyLevelId: yup.string().required(DEFAULT_ERROR_MSG),
            deadline: yup.string().required(DEFAULT_ERROR_MSG),
            description: yup.string().required(DEFAULT_ERROR_MSG),
            areaId: yup.string().required(DEFAULT_ERROR_MSG),
            majorId: yup.string().required(DEFAULT_ERROR_MSG),
            VacancyWorkPlaceId: yup.string().required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: async (values: CreateVacancyModel) => {
            await dispatch(createVacancyAction({
                countryId: values.countryId,
                cityId: values.cityId,
                title: values.title,
                responsbilities: values.responsibilities,
                vacancyLevelId: values.vacancyLevelId,
                requirements: values.requirements,
                deadline: values.deadline,
                skillIds: values.skillIds,
                description: values.description,
                areaId: values.areaId,
                majorId: values.majorId,
                vacancyWorkPlaceId: values.VacancyWorkPlaceId
            }, () => {
                notification.success({
                    message: "Vacancy Created Successfully",
                    description: "Your vacancy will now appear for all aplicants"
                  });
                navigate(DASHBOARD_VACANCIES_PATH);
            }));
        }
    });

    return formik;
}

export {
    useCreateVacancyFormik
}