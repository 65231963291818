import { Empty, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { DownArrowBlack } from "assets/icons";
import CompanyApplicationCard from "components/CompanyApplicationCard/CompanyApplicationCard";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { listVacancyApplicationsAction } from "redux/actions/applicationActions/listVacancyApplicationsAction";
import { listVacanciesAction } from "redux/actions/vacancyActions/listVacanciesAction";

const Applications = () => {
  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { vacAppLoading, vacancyApplications } = useSelector(
    (state: RootState) => state.listVacancyApplicationsReducer
  );

  const { vacanciesLoading, vacancies } = useSelector(
    (state: RootState) => state.listVacanciesReducer
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("vacancyId")) {
      dispatch(
        listVacancyApplicationsAction(searchParams.get("vacancyId") ?? "1")
      );
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(listVacanciesAction());
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-start gap-y-4 pl-8 pt-8">
      <h1 className="font-bold text-2xl">Applications</h1>
      <div className="w-full flex flex-row gap-x-5 items-center">
        {/* {
          <p className="inline">
            You are now viewing all applications on the vacancy:{" "}
            <p className="inline text-dashboard_primary font-Avenir_bold">
              {vacancyApplications?.length > 0
                ? applicationsById[0]?.vacancy?.title
                : ""}
            </p>
          </p>
        }
        { (
          <p className="inline">
            You are now viewing:{" "}
            <p className="inline text-dashboard_primary font-Avenir_bold">
              All Applications
            </p>
          </p>
        )} */}

        <Spin wrapperClassName="full-width-spin" spinning={vacanciesLoading}>
          <Select
            className="black-border select-black-text w-[40%] h-[48px] flex flex-col rounded-[15px] text-black justify-center border items-center placeholder:!text-black"
            placeholder="Filter Applications By Vacancy"
            suffixIcon={<img src={DownArrowBlack} alt="arrow" />}
            onSelect={(value: number) => {
              navigate(`/dashboard/applications?vacancyId=${value}`);
            }}
            value={
              searchParams?.get("vacancyId")
                ? parseInt(searchParams?.get("vacancyId") ?? "1")
                : null
            }
            options={vacancies?.map((item: any) => {
              return {
                label: item.title,
                value: item.vacancyId,
              };
            })}
          />
        </Spin>
      </div>
      {(vacancyApplications?.length <= 0 || !searchParams.get("vacancyId")) && (
        <Empty
          description={
            searchParams?.get("vacancyId")
              ? "No Applications On This Vacancy Yet"
              : "Please Choose a Vacancy To View it's Applications"
          }
        />
      )}
      <div className="w-full h-full flex flex-col items-start gap-y-3">
        {searchParams.get("vacancyId") &&
          vacancyApplications?.map((item: any) => {
            return (
              <CompanyApplicationCard
                id={item?.newApplicationId}
                applicationDate={item?.applieddate?.split("T")[0]}
                applicationStatusId={
                  item?.applicationStatus?.applicationStatusId
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default Applications;
