import
{
    LIST_APPLICANT_LOADING,
    LIST_APPLICANT_SUCCESS,
    LIST_APPLICANT_FAILURE
}
from "redux/types";

const initialState = {
    applicantLoading: false,
    applicant: null,
    errors: null
}

export const listApplicantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_APPLICANT_LOADING :
            return {...state, applicantLoading: action.payload};
        case LIST_APPLICANT_SUCCESS :
            return {...state, applicant: action.payload};
        case LIST_APPLICANT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    } 
}