import
{
    LIST_APPLICANT_LOADING,
    LIST_APPLICANT_SUCCESS,
    LIST_APPLICANT_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listApplicantEndpoint } from "api/apiUrls";

export const listApplicantAction = (userId: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_APPLICANT_LOADING, payload: true});
        const response = await listProtectedItemsService(`${listApplicantEndpoint}?userId=${userId}`);
        dispatch({type: LIST_APPLICANT_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: LIST_APPLICANT_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_APPLICANT_LOADING, payload: false});
    }
}