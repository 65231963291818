import React, { useState } from "react";
import { SIDE_BAR_ITEMS, SidebarItemsProps } from "constants/index";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "redux/actions/getUserDataAction";
import { listVacanciesAction } from "redux/actions/vacancyActions/listVacanciesAction";

const DashboardHome = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();
  const [highlightedItem, setHighlightedItem] = useState("");

  return (
    <section className="w-full h-full min-h-[80vh] flex flex-col items-center justify-center gap-y-3">
      {SIDE_BAR_ITEMS.map((item: SidebarItemsProps) => {
        return (
          <button
            onClick={async () => {
              if (item.name === "Profile") {
                await dispatch(getUserDataAction());
              } else if (item.name === "Vacancies") {
                await dispatch(listVacanciesAction());
              }
              navigate(item.route);
            }}
            onMouseOver={() => {
              setHighlightedItem(item.name);
            }}
            onMouseLeave={() => {
              setHighlightedItem("");
            }}
            className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] h-[6rem] rounded-md bg-white flex items-center justify-center gap-x-5 hover:bg-dashboard_primary text-gray-400 hover:!text-white"
          >
            <img
              src={
                highlightedItem === item.name
                  ? item.highlightedIcon
                  : item.selectedIcon
              }
              alt="icon"
              className="w-[2rem] h-[2rem]"
            />
            <p
              className={`text-xl ${
                highlightedItem === item.name
                  ? "text-dashboard_secondary"
                  : "text-black"
              }`}
            >
              {item.name}
            </p>
          </button>
        );
      })}
    </section>
  );
};

export default DashboardHome;
