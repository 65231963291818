import { useFormik } from "formik";
import * as yup from "yup";
import StepOneRegisterModel from "models/StepOneRegisterModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { stepOneAction } from "redux/actions/registerActions/stepOneAction";
import { useEffect } from "react";

const useStepOneFormik = (setState: React.Dispatch<React.SetStateAction<string>>) => {
    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            ProfileLogo: null,
            CoverLogo: null,
            CompanyName: '',
            Email: '',
            Password: '',
            ConfirmPassword: ''
        },
        validationSchema: yup.object({
            CompanyName: yup.string().required(DEFAULT_ERROR_MSG),
            Email: yup.string().email('Enter a valid email').required(DEFAULT_ERROR_MSG),
            Password: yup.string()
            .min(8, "password must be at least 8 characters long")
            .matches(
              new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*-_?&#]{8,}$/
              ),
              "Must contain lowercase, uppercase, number, and one special character"
            )
            .required("This field is required"),
            ConfirmPassword: yup.string().oneOf([yup.ref('Password')], 'Passwords must match').required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: async (values: StepOneRegisterModel) => {
            const formData: any = {
                UserRole: "Employer",
                CompanyName: values.CompanyName,
                Email: values.Email,
                Password: values.Password,
                ConfirmPassword: values.ConfirmPassword
            };

            if (values.ProfileLogo === null) {
                notification.error({
                    message: 'Please Enter a Profile Logo'
                });
                return;
            }

            formData.ProfileLogo = values.ProfileLogo;

            if (values.CoverLogo === null) {
                notification.error({
                    message: 'Please Enter a Cover Logo'
                });
                return;
            }

            formData.CoverLogo = values.CoverLogo;

            await dispatch(stepOneAction(formData, () => {
                setState("STEPTWO");
            }));
        }
    });

    return formik;
}

export {
    useStepOneFormik
}