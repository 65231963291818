import { PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DASHBOARD_CREATE_VACANCY_PATH } from "Routes/paths";
import { listVacanciesAction } from "redux/actions/vacancyActions/listVacanciesAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import VacancyCard from "components/VacancyCard/VacancyCard";
import { Empty } from "antd";

const Vacancies = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { vacancies } = useSelector(
    (state: RootState) => state.listVacanciesReducer
  );

  useEffect(() => {
    dispatch(listVacanciesAction());
  }, []);

  return (
    <section className="w-full h-full min-h-[90vh] flex flex-col pb-10">
      <div className="w-full flex flex-col px-10 py-10">
        <div className="w-full flex flex-row items-center justify-between">
          <p className="font-bold text-3xl">Vacancies</p>
          {/* {!isMax751 && (
                <button
                //   onClick={() => navigate(CREATE_VACANCY_PATH)}
                  className="bg-dashboard_primary flex items-center justify-center gap-x-3 px-4 py-4 rounded-full"
                >
                  <PlusOutlined className="text-white text-2xl font-bold" />
                </button>
              )} */}
          <button
            onClick={() => navigate(DASHBOARD_CREATE_VACANCY_PATH)}
            className="bg-dashboard_primary rounded-lg flex items-center justify-center gap-x-3 px-4 py-4"
          >
            <p className="text-white font-Avenir_demi">Create Vacancy</p>
            <PlusOutlined className="text-white text-2xl font-bold" />
          </button>
        </div>
      </div>
      <div className="mt-5 w-full flex flex-col gap-y-4 px-10">
        {vacancies?.length === 0 && (
          <Empty description="No Vacancies Present" />
        )}
        {vacancies?.map((item: any) => {
          return (
            <VacancyCard
              id={item.vacancyId}
              city={item.city?.cityNameEn}
              country={item.country?.countryNameEn}
              positionTitle={item.title}
              jobType={item.vacancyLevelId}
              descriptionBrief={item.description}
              deleteCallback={async () => {
                dispatch(listVacanciesAction());
              }}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Vacancies;
