import
{
    UPDATE_PROFILE_LOADING,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE
}
from "redux/types";

const initialState = {
    updateProfileLoading: false,
    updated: null,
    error: null,
}

export const updateProfileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_PROFILE_LOADING :
            return {...state, updateProfileLoading: action.payload};
        case UPDATE_PROFILE_SUCCESS :
            return {...state, updated: action.payload};
        case UPDATE_PROFILE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}