import api from "api";
import store from "redux/store";

export const listProtectedItemsService = async (url: string) => {
    const response = await api.get(url, {
        headers: {
            "Authorization" : `Bearer ${store.getState()?.stepOneReducer?.token}`
        }
    });
    return response;
}