import { combineReducers } from "redux";
import { authReducer } from "redux/reducers/authReducer";
import { loginReducer } from "redux/reducers/loginReducer";
import { stepOneReducer } from "redux/reducers/registerReducers/stepOneReducer";
import { stepTwoReducer } from "redux/reducers/registerReducers/stepTwoReducer";
import { stepThreeReducer } from "redux/reducers/registerReducers/stepThreeReducer";
import { genOtpReducer } from "redux/reducers/genOtpReducer";
import { confirmOtpReducer } from "redux/reducers/confirmOtpReducer";
import { listMajorsReducer } from "redux/reducers/utilReducers/listMajorsReducer";
import { listCountriesReducer } from "redux/reducers/utilReducers/listCountriesReducer";
import { listCitiesReducer } from "redux/reducers/utilReducers/listCitiesReducer";
import { getUserDataReducer } from "redux/reducers/getUserDataReducer";
import { getUserCompletionReducer } from "redux/reducers/getUserCompletionReducer";
import { listAreasReducer } from "redux/reducers/utilReducers/listAreasReducer";
import { createVacancyReducer } from "redux/reducers/vacancyReducers/createVacancyReducer";
import { listSkillsReducer } from "redux/reducers/utilReducers/listSkillsReducer";
import { updateProfileReducer } from "redux/reducers/updateProfileReducer";
import { listVacanciesReducer } from "redux/reducers/vacancyReducers/listVacanciesReducer";
import { updateVacancyReducer } from "redux/reducers/vacancyReducers/updateVacancyReducer";
import { listSingleVacancyReducer } from "redux/reducers/vacancyReducers/listSingleVacancyReducer";
import { listVacancyApplicationsReducer } from "redux/reducers/applicationReducers/listVacancyApplicationsReducer";
import { listSingleApplicationReducer } from "redux/reducers/applicationReducers/listSingleApplicationReducer";
import { listApplicantReducer } from "redux/reducers/applicationReducers/listApplicantReducer";
import { updateApplicationStatusReducer } from "redux/reducers/applicationReducers/updateApplicationStatusReducer";
import { deleteVacancyReducer } from "redux/reducers/vacancyReducers/deleteVacancyReducer";
import { forgotPasswordOneReducer } from "redux/reducers/forgotPasswordReducers/forgotPasswordOneReducer";
import { forgotPasswordTwoReducer } from "redux/reducers/forgotPasswordReducers/forgotPasswordTwoReducer";
import { forgotPasswordThreeReducer } from "redux/reducers/forgotPasswordReducers/forgotPasswordThreeReducer";
import { listSubscriptionPlansReducer } from "redux/reducers/listSubscriptionPlansReducer";

export const appReducer = combineReducers({
  authReducer,
  stepOneReducer,
  stepTwoReducer,
  stepThreeReducer,
  loginReducer,
  genOtpReducer,
  confirmOtpReducer,
  listMajorsReducer,
  listCountriesReducer,
  listCitiesReducer,
  getUserDataReducer,
  getUserCompletionReducer,
  listAreasReducer,
  createVacancyReducer,
  listSkillsReducer,
  updateProfileReducer,
  listVacanciesReducer,
  updateVacancyReducer,
  listSingleVacancyReducer,
  listVacancyApplicationsReducer,
  listSingleApplicationReducer,
  listApplicantReducer,
  updateApplicationStatusReducer,
  deleteVacancyReducer,
  forgotPasswordOneReducer,
  forgotPasswordTwoReducer,
  forgotPasswordThreeReducer,
  listSubscriptionPlansReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
