import { Illustration } from "assets/icons";
import { ImageIllustration } from "assets/images";

const Hero = () => {
  return (
    <section className="bg-main py-[100px] scrn-767:py-[200px] px-[25px] scrn-767:px-[50px] scrn-1025:px-0 scrn-1025:py-[300px] relative overflow-x-hidden">
      <div className="w-full mx-auto max-w-[767px] scrn-767:max-w-[1200px] flex justify-between">
        <div className="w-full scrn-767:w-1/2 flex flex-col items-start gap-5">
          <h1
            style={{
              lineHeight: "1.2",
            }}
            className="text-3xl scrn-767:text-4xl scrn-1025:text-[3.5rem] font-bold text-pro_text_light"
          >
            <span className="text-[#5E85ED] mr-3">Unlock</span>
            <span>Egypt&apos;s Top Emerging Talent</span>
          </h1>
          <p className="text-white font-medium scrn-767:max-w-[70%]">
            Connect with motivated undergraduates and recent graduates ready to
            drive your business forward.
          </p>
        </div>
      </div>
      <div className="max-w-[767px] scrn-767:max-w-[1200px] mx-auto mt-[400px] overflow-x-hidden scrn-767:mt-0">
        <div
          style={{
            backgroundImage: `url('${Illustration}')`,
            backgroundSize: "125% auto",
            backgroundRepeat: "no-repeat",
          }}
          className="w-full scrn-767:w-1/2 absolute scrn-767:right-0 bottom-0"
        >
          <img
            src={ImageIllustration}
            alt="a man holding a laptop"
            className="w-[440px] scrn-1025:w-[625px] scrn-767:h-full"
          />
        </div>
      </div>
    </section>
    // <section className="bg-main">
    //   <div className="w-full mx-auto max-w-[1440px] flex flex-col scrn-795:flex-row justify-around">
    //     <div className="w-full mt-[108px] scrn-795:mt-0 scrn-795:w-1/2 h-[20vh] scrn-795:h-screen flex flex-col items-center justify-center gap-y-6">
    //       <h1 className="text-[32px] scrn-1013:text-[56px] text-secondary font-bold max-w-[90%] scrn-1249:max-w-[80%] scrn-1428:max-w-[70%]">
    //         <span className="text-[rgb(94,133,237)]">Unlock</span> Egypt&apos;s
    //         Top Emerging Talent
    //       </h1>
    //       <p className="text-white font-normal text-left scrn-488:text-center scrn-795:text-left max-w-[90%] scrn-1249:max-w-[80%] scrn-1428:max-w-[70%] text-[16px] scrn-1013:text-[20px]">
    //         Connect with motivated undergraduates and recent graduates ready to
    //         drive your business forward.
    //       </p>
    //     </div>
    //     <div className="w-full scrn-795:w-3/4 scrn-980:w-1/2 h-screen relative overflow-hidden flex flex-col items-center">
    //       <div
    //         style={{
    //           backgroundImage: `url('${Illustration}')`,
    //           backgroundPosition: "center",
    //           backgroundRepeat: "no-repeat",
    //         }}
    //         className="min-h-[80%] scrn-795:min-h-screen w-full scrn-795:w-[130%] bg-illustration-mob scrn-795:bg-illustration-full object-cover absolute z-10 top-3 scrn-795:-top-3 scrn-795:-right-[15rem] scrn-980:-right-[17rem]"
    //       />
    //       <div
    //         style={{
    //           backgroundImage: `url('${ImageIllustration}')`,
    //           backgroundPosition: "center",
    //           backgroundSize: "100% 100%",
    //           backgroundRepeat: "no-repeat",
    //         }}
    //         className="min-w-[600px] min-h-[650px] w-[85%] object-cover absolute z-10 top-24"
    //       />
    //     </div>
    //   </div>
    // </section>
  );
};

export default Hero;
