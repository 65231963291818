import { FC } from "react";
import { CheckIcon } from "assets/icons";

export type pricingIntervalType = {
  totalAmount: string;
  per: number;
  value: "Year" | "Month";
  uniqueIdentifier: string;
  discountAppliedText?: string;
};

export interface SubscribtionCardProps {
  id: number;
  planName: string;
  planDescription: string;
  pricingIntervals: pricingIntervalType[];
  features: any[];
  setActivePop?: any;
}

export const SubscriptionCard: FC<SubscribtionCardProps> = ({
  id,
  planName,
  planDescription,
  pricingIntervals,
  features,
  setActivePop,
}) => {
  return (
    <div className="w-full max-h-[535px] overflow-y-auto min-h-[400px] bg-white rounded-[15px] shadow-shadow_main flex flex-col items-start gap-y-4 py-4 px-3">
      <div className="w-full flex flex-row items-center gap-x-4">
        <p className="font-Avenir_demi text-[#142644] text-[16px] md:text-[20px]">
          {planName}
        </p>
        <p className="font-Avenir_demi text-[#444444] text-[12px] md:text-[14px]">
          {planDescription}
        </p>
      </div>
      <div className="w-full flex items-start justify-center gap-x-4">
        {pricingIntervals.map((item: pricingIntervalType) => {
          return (
            <div className="w-[30%] flex flex-col justify-start">
              <button className="w-full py-2 bg-[#DCDEE3] rounded-[15px]">
                <p className="text-[#142644] font-Avenir_demi text-[12px] md:text-[16px] text-center">
                  {item.totalAmount} EGP <br /> /{item.per} {item.value}
                </p>
              </button>
              {item.discountAppliedText && (
                <p className="text-center text-[#444444] font-Avenir_demi text-[10px] md:text-[14px]">
                  {item.discountAppliedText}
                </p>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-full flex flex-col items-start gap-y-6">
        {features.map((item: { features: string }) => {
          return (
            <div className="w-full flex items-center gap-x-2">
              <img src={CheckIcon} alt="check-icon" />
              <p className="text-[#142644] font-Avenir_demi text-[12px] md:text-[16px]">
                {item.features}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionCard;
