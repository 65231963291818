import { FC, useState } from "react";
import { EditIcon, LocationIcon, TrashIcon } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
// import { getVacancyAction } from "redux/actions/vacanciesActions/getVacancyAction";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_UPDATE_VACANCY_PATH } from "Routes/paths";
import { Modal, Spin } from "antd";
import { listSingleVacancyAction } from "redux/actions/vacancyActions/listSingleVacancyAction";
import { listVacancyApplicationsAction } from "redux/actions/applicationActions/listVacancyApplicationsAction";
import { deleteVacancyAction } from "redux/actions/vacancyActions/deleteVacancyAction";

export interface VacancyCardProps {
  id: number;
  positionTitle: string;
  country: string;
  city: string;
  jobType: number;
  descriptionBrief: string;
  deleteCallback: () => void;
}

const VacancyCard: FC<VacancyCardProps> = ({
  id,
  positionTitle,
  country,
  city,
  jobType,
  descriptionBrief,
  deleteCallback,
}) => {
  const { vacancyLoading } = useSelector(
    (state: RootState) => state.listSingleVacancyReducer
  );
    const { deleteLoading } = useSelector(
      (state: RootState) => state.deleteVacancyReducer
    );

  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const handleClick = async () => {
    await dispatch(listSingleVacancyAction(id.toString()));
    navigate(DASHBOARD_UPDATE_VACANCY_PATH);
  };

  const handleDeleteClick = async () => {
    await dispatch(deleteVacancyAction(id.toString()));
    setModalOpen(false);
    deleteCallback();
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleViewClick = async () => {
    await dispatch(listVacancyApplicationsAction(id?.toString()));
    navigate(`/dashboard/applications?vacancyId=${id}`);
  };

  //   const { isMax751 } = useResponsive();

  const isMax751 = true;

  return (
    <>
      <Modal
        open={modalOpen}
        confirmLoading={deleteLoading}
        onOk={handleDeleteClick}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{
          style: {
            backgroundColor: "red",
          },
        }}
        title={
          <h2 className="text-black font-bo">Delete Vacancy Confirmation</h2>
        }
      >
        <p>Are you sure you want to delete vacancy for: {positionTitle}</p>
      </Modal>
      <Spin wrapperClassName="spin-full-width" spinning={vacancyLoading}>
        <div
          className={`w-full sm:w-[90%] md:w-[80%] lg:w-[70%] flex ${
            isMax751 ? "flex-row" : "flex-col"
          } gap-x-5 gap-y-3`}
        >
          <div
            onClick={handleViewClick}
            className="w-full px-5 py-5 flex flex-col items-start gap-y-3 bg-white shadow-md hover:cursor-pointer"
          >
            <p className="text-black font-Avenir_demi font-bold text-[24px]">
              {positionTitle}
            </p>
            <div className="flex flex-row items-center gap-x-3">
              <img src={LocationIcon} alt="location-icon" />
              <p className="text-dashboard_gray font-Avenir_demi text-md">
                {country}, {city}
              </p>
              <div className="bg-dashboard_primary rounded-full px-4 py-3">
                <p className="text-white font-Avenir_demi text-sm">
                  {jobType === 1 ? "Internship" : "Entry-Level"}
                </p>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: descriptionBrief }}
              style={{
                listStyleType: "none",
              }}
              className="text-sm text-dashboard_gray font-Avenir_demi px-5"
            ></div>
          </div>
          <div
            className={`flex ${
              isMax751 ? "flex-col" : "flex-row"
            } items-start justify-between`}
          >
            <div
              onClick={handleClick}
              className="w-[3.5rem] h-[3rem] flex items-center justify-center hover:cursor-pointer bg-white rounded-lg p-3"
            >
              <img src={EditIcon} alt="edit" className="w-[2rem] h-[2rem]" />
            </div>
            <div
              onClick={() => setModalOpen(true)}
              className="w-[3.5rem] h-[3rem] flex items-center justify-center hover:cursor-pointer bg-white rounded-lg p-3"
            >
              <img src={TrashIcon} alt="trash" className="w-[2rem] h-[2rem]" />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default VacancyCard;
