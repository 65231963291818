import { Popover } from "antd";
import { useState } from "react";
import { BlackCompanyProfileIcon, LogoutIcon } from "assets/icons";
import { useDispatch } from "react-redux";
import { LOG_OUT } from "redux/types";
import { useNavigate } from "react-router";
import { DASHBOARD_PROFILE_PATH, HOME_PATH } from "Routes/paths";
import { getUserDataAction } from "redux/actions/getUserDataAction";

const CompaniesNavbar = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [popverOpen, setPopoverOpen] = useState(false);

  const handleLogout = () => {
    dispatch({ type: LOG_OUT });
    navigate(HOME_PATH);
  };

  return (
    <nav
      style={{
        width: "calc(100% - 300px)",
      }}
      className="h-[80px] z-10 bg-dashboard_primary fixed top-0 flex flex-row items-center justify-end px-5"
    >
      <Popover
        content={
          <div className="w-[120px] flex flex-col gap-y-6">
            <button
              onClick={async () => {
                await dispatch(getUserDataAction());
                navigate(DASHBOARD_PROFILE_PATH);
                setPopoverOpen(false);
              }}
              className="flex items-center gap-x-3"
            >
              <img
                src={BlackCompanyProfileIcon}
                className="max-w-[20px] max-h-[20px]"
                alt="profile"
              />
              <p>Profile</p>
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center gap-x-3"
            >
              <img
                src={LogoutIcon}
                className="max-w-[20px] max-h-[20px]"
                alt="profile"
              />
              <p>Logout</p>
            </button>
          </div>
        }
        trigger="click"
        open={popverOpen}
        onOpenChange={setPopoverOpen}
      >
        <div className="w-12 h-12 rounded-full p-2 flex items-center justify-center bg-white hover:cursor-pointer">
          <img
            src={BlackCompanyProfileIcon}
            alt="profile"
            className="max-w-[22px] max-h-[22px]"
          />
        </div>
      </Popover>
    </nav>
  );
};

export default CompaniesNavbar;
