import
{
    LIST_SKILLS_LOADING,
    LIST_SKILLS_SUCCESS,
    LIST_SKILLS_FAILURE
}
from "redux/types";

const initialState = {
    skillsLoading: false,
    skills: null,
    errors: null
}

export const listSkillsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_SKILLS_LOADING :
            return {...state, skillsLoading: action.payload};
        case LIST_SKILLS_SUCCESS :
            return {...state, skills: action.payload};
        case LIST_SKILLS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}