import { Outlet, useNavigate } from "react-router";
import Dashboard from "./Dashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { getUserCompletionAction } from "redux/actions/getUserCompletionAction";
import { DASHBOARD_HOME_PATH, HOME_PATH } from "Routes/paths";

const DashboardParent = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.stepOneReducer);

  useEffect(() => {
    dispatch(
      getUserCompletionAction((data: any) => {
        if (!data?.isCompleted) {
          navigate(HOME_PATH);
        }
      })
    );
  }, [user]);

  return (
    <Dashboard>
      <Outlet />
    </Dashboard>
  );
};

export default DashboardParent;
