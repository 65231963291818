
export const loginEndpoint = "FlowCompany/Login";
export const stepOneEndpoint = "FlowCompany/AddCompanyNameAndImage";
export const stepTwoEndpoint = "FlowCompany/AddCompanyInfo";
export const stepThreeEndpoint = "FlowCompany/AddMediaLinks";
export const completeAuthEndpoint = "NewAuth/complete";
export const checkCompletionEndpoint = "NewAuth/UserData";
export const generateOtpEndpoint = "FlowCompany/generate-otp-Company"
export const confirmOtpEndpoint = "FlowCompany/validate-otp-Company";
export const listUserDataEndpoint = "FlowCompany/MyCompanyData";
export const updateUserDataEndpoint = "FlowCompany/EditCompanyProfile";

/* Util Endpoints */
export const listCompanyMajorsEndpoint = "CompanyFields";
export const listCountriesEndpoint = "Countries/GetAll";
export const listCitiesEndpoint = "Cities/GetCitiesBy";
export const listAreasEndpoint = "Areas/GetAreaByCityId";
export const listSkillsEndpoint = "Skills/GetAllSkills";

/* Vacancy Endpoints */
export const createVacancyEndpoint = "NewVacancies/AddVacancy";
export const listVacanciesEndpoint = "FlowCompany/AllCompanyVacansies";
export const updateVacancyEndpoint = "NewVacancies/EditVacancy";
export const listSingleVacancyEndpoint = "NewVacancies/GetVacancyBy";
export const deleteVacancyEndpoint = "NewVacancies/DeleteVacancyBy";

/* Application Endpoints */
export const listVacancyApplicationsEndpoint = "NewApplication/GetApplicationByVacancyId";
export const listSingleApplicationEndpoint = "NewApplication/GetApplication";
export const listApplicantEndpoint = "NewUserProfiles/GetUserProfileByUserIdForCompany";
export const updateApplicationStatusEndpoint = "NewApplication/UpdateApplicationStatus";

/* Forgot Password Endpoints */
export const forgotPasswordOneEndpoint = "NewAuth/generate-otp";
export const forgotPasswordTwoEndpoint = "NewAuth/validate-otp";
export const forgotPasswordThreeEndpoint = "NewAuth/reset-password";

/* Get Subscription Plans Endpoint */
export const getSubscriptionPlansEndpoint = "SubscriptionPlan/GetAll";