import
{
    USER_DATA_LOADING,
    USER_DATA_SUCCESS,
    USER_DATA_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listProtectedItemsService } from "services/listProtectedItemsService";
import { listUserDataEndpoint } from "api/apiUrls";

export const getUserDataAction = (callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: USER_DATA_LOADING, payload: true});
        const response = await listProtectedItemsService(listUserDataEndpoint);
        dispatch({type: USER_DATA_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: USER_DATA_FAILURE, payload: err});
    }
    finally {
        dispatch({type: USER_DATA_LOADING, payload: false});
    }
}