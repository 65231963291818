import
{   
    LIST_VAC_APP_LOADING,
    LIST_VAC_APP_SUCCESS,
    LIST_VAC_APP_FAILURE
}
from "redux/types";

const initialState = {
    vacAppLoading: false,
    vacancyApplications: null,
    errors: null
}

export const listVacancyApplicationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_VAC_APP_LOADING :
            return {...state, vacAppLoading: action.payload};
        case LIST_VAC_APP_SUCCESS :
            return {...state, vacancyApplications: action.payload};
        case LIST_VAC_APP_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    } 
}