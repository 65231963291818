import { useFormik } from "formik"
import * as yup from "yup";
import SocialLinksRegisterModel from "models/SocialLinksRegisterModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { stepThreeAction } from "redux/actions/registerActions/stepThreeAction";
import { useNavigate } from "react-router";
import { DASHBOARD_HOME_PATH } from "Routes/paths";

const useSocialLinksFormik = (setState: React.Dispatch<React.SetStateAction<string>>) => {
    const navigate = useNavigate();

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            webSite: '',
            facebookLink: '',
            instagramLink: '',
            linkedInLink: '',
            xLink: ''    
        },
        validationSchema: yup.object({
            webSite: yup.string(),
            facebookLink: yup.string(),
            instagramLink: yup.string(),
            linkedInLink: yup.string(),
            xLink: yup.string()
        }),
        onSubmit: (values: SocialLinksRegisterModel) => {
            const formData: any = {
                webSite: values.webSite,
                facebookLink: values.facebookLink,
                instagramLink: values.instagramLink,
                linkedInLink: values.linkedInLink,
                xLink: values.xLink
            };

            dispatch(stepThreeAction(formData, () => {
                navigate(DASHBOARD_HOME_PATH);
            }));
        }
    });

    return formik;
}

export {
    useSocialLinksFormik
}