import
{
    LIST_PLANS_LOADING,
    LIST_PLANS_SUCCESS,
    LIST_PLANS_FAILURE
}
from "redux/types";

const initialState = {
    plansLoading: false,
    plans: null,
    errors: null
}

export const listSubscriptionPlansReducer = (state = initialState, action: any) =>  {
    switch (action.type) {
        case LIST_PLANS_LOADING :
            return {...state, plansLoading: action.payload};
        case LIST_PLANS_SUCCESS :
            return {...state, plans: action.payload};
        case LIST_PLANS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}